import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'

import { verifyUserEmailRequest } from '~/actions/public/user-email-verification'

class UserEmailVerification extends React.Component
{
	componentDidMount() {
		const { verifyUserEmailRequest } = this.props
		const parsed = queryString.parse(this.props.location.search)
		const { id, token } = parsed
		verifyUserEmailRequest(id, token)
	}

	render() {
		const { loading, success } = this.props
		return (
			<>
				{ loading ? (
					<p>Loading...</p>
				) : (
					<h1>{ success ? 'Success' : 'Error' }</h1>
				)}
			</>
		)
	}
}

UserEmailVerification.propTypes = {
	verifyUserEmailRequest: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	success: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
	const { userEmailVerification } = state.public
	const { loading, success } = userEmailVerification
	return {
		loading,
		success,
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		verifyUserEmailRequest,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(UserEmailVerification)
