import { SCOPES } from '~/actions/types'

export const listRequest = () => ({
	type: SCOPES.LIST_REQUEST,
})

export const listFailure = (error) => ({
	type: SCOPES.LIST_FAILURE,
	error,
})

export const listSuccess = (list) => ({
	type: SCOPES.LIST_SUCCESS,
	list,
})

export const creationRequest = () => ({
	type: SCOPES.CREATION_REQUEST,
})

export const creationFailure = (error) => ({
	type: SCOPES.CREATION_FAILURE,
	error,
})

export const creationSuccess = (scope) => ({
	type: SCOPES.CREATION_SUCCESS,
	scope,
})

export const deleteRequest = (scope) => ({
	type: SCOPES.DELETE_REQUEST,
	scope,
})

export const deleteFailure = (error) => ({
	type: SCOPES.DELETE_FAILURE,
	error,
})

export const deleteSuccess = () => ({
	type: SCOPES.DELETE_SUCCESS,
})

export const updateRequest = (scope) => ({
	type: SCOPES.UPDATE_REQUEST,
	scope,
})

export const updateFailure = (error) => ({
	type: SCOPES.UPDATE_FAILURE,
	error,
})

export const updateSuccess = () => ({
	type: SCOPES.UPDATE_SUCCESS,
})
