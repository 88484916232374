import { PUBLIC } from '~/actions/types'

export const verifyUserEmailRequest = (id, token) => ({
	type: PUBLIC.VERIFY_USER_EMAIL_REQUEST,
	id,
	token,
})

export const verifyUserEmailFailure = (error) => ({
	type: PUBLIC.VERIFY_USER_EMAIL_FAILURE,
	error,
})

export const verifyUserEmailSuccess = () => ({
	type: PUBLIC.VERIFY_USER_EMAIL_SUCCESS,
})
