import { spawn } from 'redux-saga/effects'

import adminAuthentication from './admin/authentication'
import adminApplications from './admin/applications'
import adminUsers from './admin/users'
import adminScopes from './admin/scopes'
import consentAuthentication from './consent/authentication'
import consentAsk from './consent/ask'
import publicUserEmailVerification from './public/user-email-verification'

export default function* appSagas() {
	yield spawn(adminAuthentication)
	yield spawn(adminApplications)
	yield spawn(adminUsers)
	yield spawn(adminScopes)
	yield spawn(consentAuthentication)
	yield spawn(consentAsk)
	yield spawn(publicUserEmailVerification)
}
