import React from 'react'

import ConfirmModalComponent from '~/components/modal-types/ConfirmModal'

class ConfirmModal extends React.PureComponent
{
	autofocusDOMRef = null
	state = {}

	componentDidMount() {
		if (this.autofocusDOMRef) {
			setImmediate(() => this.autofocusDOMRef.focus())
		}
	}

	setAutofocusDOMRef = (element) => {
		this.autofocusDOMRef = element
	}

	render() {
		const { t, cancelAction, confirmAction, title, message } = this.props
		return (
			<ConfirmModalComponent
				t={t}
                cancelAction={cancelAction}
                confirmAction={confirmAction}
				title={title}
				message={message}
				setAutofocusDOMRef={this.setAutofocusDOMRef}
			/>
		)
	}
}

export default ConfirmModal
