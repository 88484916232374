import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import classNames from 'classnames'
import { connect } from 'react-redux'

import history from '~/utils/history'
import Notifications from '~/containers/Notifications'
import Modals from '~/containers/Modals'
import Sidebar from '~/containers/Sidebar'
import NotFound from '~/routes/NotFound'

import LoginConsentRoute from '~/routes/consent/Login'
import AskConsentRoute from '~/routes/consent/Ask'

import AdminPrivateRoute from '~/routes/admin/PrivateRoute'
import LoginAdminRoute from '~/routes/admin/Login'
import LogoutAdminRoute from '~/routes/admin/Logout'
import DashboardAdminRoute from '~/routes/admin/Dashboard'
import ApplicationListAdminRoute from '~/routes/admin/ApplicationList'
import ApplicationDetailsAdminRoute from '~/routes/admin/ApplicationDetails'
import ApplicationCreationAdminRoute from '~/routes/admin/ApplicationCreation'
import UserListAdminRoute from '~/routes/admin/UserList'
import UserDetailsAdminRoute from '~/routes/admin/UserDetails'
import UserCreationAdminRoute from '~/routes/admin/UserCreation'
import ScopeListAdminRoute from '~/routes/admin/ScopeList'

import UserEmailVerificationPublicRoute from '~/routes/public/UserEmailVerification'

const App = ({ notifications, authentication }) => {
	const hasSidebar = authentication.loggedIn
	const isConsentPart = history.location.pathname.startsWith('/consent')
	return (
		<div className="app">
			<Router history={history}>
				<Route path="/admin" render={() => hasSidebar ? <Sidebar /> : null} />
				<div className={classNames('container', { 'has-sidebar': hasSidebar, 'is-consent-part': isConsentPart })}>
					<Switch>
						<Route path="/admin" render={() => (
							<div className="content-wrapper" role="main">
								<section className="content-inner">
									<Switch>
										<Route path="/admin/login" component={LoginAdminRoute} />
										<Route path="/admin/logout" component={LogoutAdminRoute} />

										<AdminPrivateRoute exact path="/admin/dashboard" component={DashboardAdminRoute} />
										<AdminPrivateRoute exact path="/admin/applications" component={ApplicationListAdminRoute} />
										<AdminPrivateRoute exact path="/admin/applications/new" component={ApplicationCreationAdminRoute} />
										<AdminPrivateRoute exact path="/admin/application/:clientId" component={ApplicationDetailsAdminRoute} />
										<AdminPrivateRoute exact path="/admin/users" component={UserListAdminRoute} />
										<AdminPrivateRoute exact path="/admin/users/new" component={UserCreationAdminRoute} />
										<AdminPrivateRoute exact path="/admin/user/:userId" component={UserDetailsAdminRoute} />
										<AdminPrivateRoute exact path="/admin/scopes" component={ScopeListAdminRoute} />
										{ authentication.loggedIn ? <Redirect from="/admin" to="/admin/dashboard" /> : <Redirect from="/admin" to="/admin/login" /> }
										<Route component={NotFound} />
									</Switch>
								</section>
							</div>
						)} />

						<Route path="/consent" render={() => (
							<Switch>
								<Route path="/consent/login" component={LoginConsentRoute} />
								<Route path="/consent/ask" component={AskConsentRoute} />
								<Route component={NotFound} />
							</Switch>
						)} />

						<Route path="/u/email-verification" component={UserEmailVerificationPublicRoute} />
						<Route component={NotFound} />
					</Switch>
				</div>
			</Router>
			<Notifications notifications={notifications} />
			<Modals />
		</div>
	)
}

export default connect(
	({ notifications, admin }) => ({
		notifications,
		authentication: admin.authentication,
	}),
)(App)
