import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TimeAgo from 'react-timeago'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { listRequest } from '~/actions/admin/applications'

class ApplicationList extends React.Component
{
	componentDidMount() {
		const { listRequest } = this.props
		listRequest()
	}

	render() {
		const { applications, loading } = this.props
		return (
			<>
				<h1 className="content-title">
					Applications List <Link to="/admin/applications/new" className="button default btn-create-new-above-list">+ Create application</Link>
				</h1>

				{ loading && <p>Loading...</p> }

				{ applications.length ? (
					<table className="application-list">
						<thead>
							<tr>
								<th>Type</th>
								<th>Name</th>
								<th>Client id</th>
								<th>Creation date</th>
							</tr>
						</thead>
						<tbody>
							{ applications.map(app => {
								const { client_id, client_name, created_at, metadata = {} } = app
								const { is_first_party_client, flow_type } = metadata
								return (
									<tr key={client_id}>
										<td title={flow_type} className={classNames({ 'first-party-client': is_first_party_client })}>
											{ is_first_party_client === true && <div className="first-party-client-marker"></div> }
											<img src={`/static/oauth2-flow-types/${flow_type}.svg`} alt={`${flow_type} flow type`} aria-hidden="true" />
										</td>
										<td><Link to={`/admin/application/${client_id}`}>{client_name}</Link></td>
										<td>{client_id}</td>
										<td><TimeAgo date={created_at} /></td>
									</tr>
								)
							})}
						</tbody>
					</table>
				) : (
					<p>No application found.</p>
				)}
			</>
		)
	}
}

ApplicationList.propTypes = {
	applications: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
}

ApplicationList.defaultProps = {
	applications: [],
	loading: false,
}

const mapStateToProps = ({ admin }) => {
	const { applications } = admin
	return {
		loading: applications.loading,
		applications: applications.list,
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		listRequest,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationList)
