import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import getUser from '~/utils/get-admin-user'

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={props => (
		(typeof getUser() !== 'undefined')
		? <Component {...props} />
		: <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }} />
	)} />
)

export default PrivateRoute
