const tokenEndpointAuthMethod = {
	client_secret_basic: 'Client ID and Secret are sent in the HTTP Authorization header.',
	client_secret_post: 'Client ID and Secret are sent in the HTTP POST body.',
	private_key_jwt: 'a signed JWT is used to authenticate the client.',
	none: 'should be used when code is executed on untrusted devices (phone, single page app, browser app, tablet, desktop app).',
}

export const getGrantTypesCheckboxes = (flowType) => ([
	(flowType === 'machine-to-machine') && {
		name: 'grant_types_client_credentials',
		title: `Client credentials - allows to obtain an access token outside of the context of a user (for machine-to-machine flow)`,
		modificationDisabled: true,
	},
	{
		name: 'grant_types_authorization_code',
		title: `Authorization code - allows requesting tokens on a user's behalf.`,
	},
	{
		name: 'grant_types_implicit',
		title: `Implicit - allows requesting tokens on a user's behalf (for legacy single page applications).`,
	},
	{
		name: 'grant_types_refresh_token',
		title: `Refresh token - allows refreshing expired access and ID tokens.`,
	},
].filter(Boolean))

const responseTypesCheckboxes = [
	{
		name: 'response_types_code',
		title: 'Authorization code - required for performing the authorization code flow.',
	},
	{
		name: 'response_types_id_token',
		title: 'ID Token - required for requesting OpenID Connect ID Tokens.',
	},
	{
		name: 'response_types_token',
		title: 'Token - required for performing implicit flows (discouraged).',
	},
]

export {
	tokenEndpointAuthMethod,
	responseTypesCheckboxes,
}
