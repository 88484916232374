import React from 'react'
import classNames from 'classnames'
import { Route, Link as RouterLink } from 'react-router-dom'

const Divider = () => <li className="divider"></li>

const Link = ({ item }) => <RouterLink to={item.link}>{ item.icon && <i className={'icon-' + item.icon} aria-hidden="true"></i> } {item.title}</RouterLink>

const SubSidebar = ({ item }) => (
	<>
		<span>{item.title}</span>
		<SidebarContainer items={item.children} />
	</>
)

const Row = ({ item }) => (
	<Route path={item.link} children={({ match }) => (
		<li className={classNames({
			title: item.isTitle,
			link: !item.isTitle,
			active: match,
		})}>
			{ (!Array.isArray(item.children)) ? <Link item={item} /> : <SubSidebar item={item} />}
		</li>
	)} />
)

const Sidebar = ({ item }) => (item.divider !== true) ? <Row item={item} /> : <Divider />

const SidebarContainer = ({ items }) => (
	<ul>
		{ items.map((item, index) => (
			<Sidebar item={item} key={index} />
		))}
	</ul>
)

export default SidebarContainer
