import rpn from 'request-promise-native'

const origin = window.location.origin

const request = rpn.defaults({
	baseUrl: `${origin}/api/`,
	json: true,
	headers: {
		'Accept': 'application/json, text/plain',
	}
})

export default request
