import service from '~/api/service'

const apiResultCheck = (response) => {
	if (!response.success) {
		throw new Error(response.message)
	}
	return response
}

export const prepareLoginRequestApi = (challenge) => service.get(`/consent/login`, {
	qs: { challenge },
})
.then(apiResultCheck)

export const loginRequestApi = (challenge, email, password, remember) => service.post(`/consent/login`, {
	json: { challenge, email, password, remember },
})
.then(apiResultCheck)

export const prepareAskRequestApi = (challenge) => service.get(`/consent/ask`, {
	qs: { challenge },
})
.then(apiResultCheck)

export const askRequestApi = (challenge, acceptConsent, grant_scope, remember) => service.post(`/consent/ask`, {
	json: { challenge, acceptConsent, grant_scope, remember },
})
.then(apiResultCheck)
