import { takeEvery, put, call } from 'redux-saga/effects'

import * as notification from '~/actions/notifications'
import { CONSENT_AUTHENTICATION } from '~/actions/types'
import handleRequestError from '~/api/handle-request-error'
import { prepareLoginRequestApi, loginRequestApi } from '~/api/consent'
import {
	prepareLoginSuccess,
	prepareLoginFailure,
	loginSuccess,
	loginFailure,
} from '~/actions/consent/authentication'

function* onPrepareLoginRequest({ challenge }) {
	try {
		const { redirect_to, client={} } = yield call(prepareLoginRequestApi, challenge)

		yield put(prepareLoginSuccess(client))

		if (redirect_to) {
			yield put(notification.success({
				title: 'Success',
				message: 'Successfully logged in',
			}))
			window.location = redirect_to
		}
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(prepareLoginFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onLoginRequest({ challenge, email, password, remember }) {
	try {
		const { redirect_to } = yield call(loginRequestApi, challenge, email, password, remember)

		yield put(loginSuccess())

		if (redirect_to) {
			yield put(notification.success({
				title: 'Success',
				message: 'Successfully logged in',
			}))
			window.location = redirect_to
		}
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(loginFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

export default function*() {
	yield takeEvery(CONSENT_AUTHENTICATION.PREPARE_LOGIN_REQUEST, onPrepareLoginRequest)
	yield takeEvery(CONSENT_AUTHENTICATION.LOGIN_REQUEST, onLoginRequest)
}
