export const NOTIFICATIONS = {
	SHOW: 'NOTIFICATIONS_SHOW',
	HIDE: 'NOTIFICATIONS_HIDE',
	REMOVE_ALL: 'NOTIFICATIONS_REMOVE_ALL',
}

export const MODALS = {
	SHOW: 'MODALS:SHOW',
	CLOSE: 'MODALS:CLOSE',
	CLOSE_ALL: 'MODALS:CLOSE_ALL',

	ACTION_CANCEL: 'MODALS:ACTION_CANCEL',
	ACTION_CONFIRM: 'MODALS:ACTION_CONFIRM',
	ACTION_CLOSE: 'MODALS:ACTION_CLOSE',
	ACTION_PROMPT_VALUE: 'MODALS:ACTION_PROMPT_VALUE',
}

export const ADMIN_AUTHENTICATION = {
	LOGIN_REQUEST: 'ADMIN_AUTHENTICATION:LOGIN_REQUEST',
	LOGIN_SUCCESS: 'ADMIN_AUTHENTICATION:LOGIN_SUCCESS',
	LOGIN_FAILURE: 'ADMIN_AUTHENTICATION:LOGIN_FAILURE',
	LOGOUT: 'ADMIN_AUTHENTICATION:LOGOUT',
}

export const CONSENT_AUTHENTICATION = {
	PREPARE_LOGIN_REQUEST: 'CONSENT_AUTHENTICATION:PREPARE_LOGIN_REQUEST',
	PREPARE_LOGIN_SUCCESS: 'CONSENT_AUTHENTICATION:PREPARE_LOGIN_SUCCESS',
	PREPARE_LOGIN_FAILURE: 'CONSENT_AUTHENTICATION:PREPARE_LOGIN_FAILURE',
	LOGIN_REQUEST: 'CONSENT_AUTHENTICATION:LOGIN_REQUEST',
	LOGIN_SUCCESS: 'CONSENT_AUTHENTICATION:LOGIN_SUCCESS',
	LOGIN_FAILURE: 'CONSENT_AUTHENTICATION:LOGIN_FAILURE',
}

export const CONSENT_ASK = {
	PREPARE_ASK_REQUEST: 'CONSENT_ASK:PREPARE_ASK_REQUEST',
	PREPARE_ASK_SUCCESS: 'CONSENT_ASK:PREPARE_ASK_SUCCESS',
	PREPARE_ASK_SUCCESS_WITH_DATA: 'CONSENT_ASK:PREPARE_ASK_SUCCESS_WITH_DATA',
	PREPARE_ASK_FAILURE: 'CONSENT_ASK:PREPARE_ASK_FAILURE',
	ASK_REQUEST: 'CONSENT_ASK:ASK_REQUEST',
	ASK_SUCCESS: 'CONSENT_ASK:ASK_SUCCESS',
	ASK_FAILURE: 'CONSENT_ASK:ASK_FAILURE',
}

export const APPLICATIONS = {
	LIST_REQUEST: 'APPLICATIONS:LIST_REQUEST',
	LIST_SUCCESS: 'APPLICATIONS:LIST_SUCCESS',
	LIST_FAILURE: 'APPLICATIONS:LIST_FAILURE',
	CREATION_REQUEST: 'APPLICATIONS:CREATION_REQUEST',
	CREATION_FAILURE: 'APPLICATIONS:CREATION_FAILURE',
	CREATION_SUCCESS: 'APPLICATIONS:CREATION_SUCCESS',
	DETAILS_REQUEST: 'APPLICATIONS:DETAILS_REQUEST',
	DETAILS_FAILURE: 'APPLICATIONS:DETAILS_FAILURE',
	DETAILS_SUCCESS: 'APPLICATIONS:DETAILS_SUCCESS',
	DELETE_REQUEST: 'APPLICATIONS:DELETE_REQUEST',
	DELETE_FAILURE: 'APPLICATIONS:DELETE_FAILURE',
	DELETE_SUCCESS: 'APPLICATIONS:DELETE_SUCCESS',
	ROTATE_SECRET_REQUEST: 'APPLICATIONS:ROTATE_SECRET_REQUEST',
	ROTATE_SECRET_FAILURE: 'APPLICATIONS:ROTATE_SECRET_FAILURE',
	ROTATE_SECRET_SUCCESS: 'APPLICATIONS:ROTATE_SECRET_SUCCESS',
	UPDATE_DETAILS_REQUEST: 'APPLICATIONS:UPDATE_DETAILS_REQUEST',
	UPDATE_DETAILS_FAILURE: 'APPLICATIONS:UPDATE_DETAILS_FAILURE',
	UPDATE_DETAILS_SUCCESS: 'APPLICATIONS:UPDATE_DETAILS_SUCCESS',
}

export const USERS = {
	LIST_REQUEST: 'USERS:LIST_REQUEST',
	LIST_FAILURE: 'USERS:LIST_FAILURE',
	LIST_SUCCESS: 'USERS:LIST_SUCCESS',
	CREATION_REQUEST: 'USERS:CREATION_REQUEST',
	CREATION_FAILURE: 'USERS:CREATION_FAILURE',
	CREATION_SUCCESS: 'USERS:CREATION_SUCCESS',
	DETAILS_REQUEST: 'USERS:DETAILS_REQUEST',
	DETAILS_FAILURE: 'USERS:DETAILS_FAILURE',
	DETAILS_SUCCESS: 'USERS:DETAILS_SUCCESS',
	DELETE_REQUEST: 'USERS:DELETE_REQUEST',
	DELETE_FAILURE: 'USERS:DELETE_FAILURE',
	DELETE_SUCCESS: 'USERS:DELETE_SUCCESS',
	UPDATE_DETAILS_REQUEST: 'USERS:UPDATE_DETAILS_REQUEST',
	UPDATE_DETAILS_FAILURE: 'USERS:UPDATE_DETAILS_FAILURE',
	UPDATE_DETAILS_SUCCESS: 'USERS:UPDATE_DETAILS_SUCCESS',
	BLOCK_USER_REQUEST: 'USERS:BLOCK_USER_REQUEST',
	BLOCK_USER_FAILURE: 'USERS:BLOCK_USER_FAILURE',
	BLOCK_USER_SUCCESS: 'USERS:BLOCK_USER_SUCCESS',
	CHANGE_USER_PASSWORD_REQUEST: 'USERS:CHANGE_USER_PASSWORD_REQUEST',
	CHANGE_USER_PASSWORD_FAILURE: 'USERS:CHANGE_USER_PASSWORD_FAILURE',
	CHANGE_USER_PASSWORD_SUCCESS: 'USERS:CHANGE_USER_PASSWORD_SUCCESS',
}

export const SCOPES = {
	LIST_REQUEST: 'SCOPES:LIST_REQUEST',
	LIST_FAILURE: 'SCOPES:LIST_FAILURE',
	LIST_SUCCESS: 'SCOPES:LIST_SUCCESS',
	CREATION_REQUEST: 'SCOPES:CREATION_REQUEST',
	CREATION_FAILURE: 'SCOPES:CREATION_FAILURE',
	CREATION_SUCCESS: 'SCOPES:CREATION_SUCCESS',
	DELETE_REQUEST: 'SCOPES:DELETE_REQUEST',
	DELETE_FAILURE: 'SCOPES:DELETE_FAILURE',
	DELETE_SUCCESS: 'SCOPES:DELETE_SUCCESS',
	UPDATE_REQUEST: 'SCOPES:UPDATE_REQUEST',
	UPDATE_FAILURE: 'SCOPES:UPDATE_FAILURE',
	UPDATE_SUCCESS: 'SCOPES:UPDATE_SUCCESS',
}

export const PUBLIC = {
	VERIFY_USER_EMAIL_REQUEST: 'PUBLIC:VERIFY_USER_EMAIL_REQUEST',
	VERIFY_USER_EMAIL_FAILURE: 'PUBLIC:VERIFY_USER_EMAIL_FAILURE',
	VERIFY_USER_EMAIL_SUCCESS: 'PUBLIC:VERIFY_USER_EMAIL_SUCCESS',
}
