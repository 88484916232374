import React from 'react'

class AdminDashboard extends React.Component
{
	render() {
		return (
			<div>
				<h1>AdminDashboard</h1>
			</div>
		)
	}
}

export default AdminDashboard
