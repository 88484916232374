import React from 'react'
import { css } from 'emotion'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const buttonStyle = css`
	margin-left: 10px;
`

class DivShowHide extends React.PureComponent
{
	state = {
		show: false,
	}

	toggleShow = (e) => {
		e.preventDefault()
		this.setState({ show: !this.state.show })
	}

	render() {
		const { show } = this.state
		const { children, title } = this.props
		return (
			<>
				<p>
					{ title }
					<button onClick={this.toggleShow} className={classNames('default', 'no-float', buttonStyle)}>
						<i className={'icon-view-' + (show ? 'hide' : 'show')} aria-hidden="true"></i>
					</button>
				</p>
				{ show && children }
			</>
		)
	}
}

DivShowHide.propTypes = {
	children: PropTypes.any.isRequired,
	title: PropTypes.node.isRequired,
}

export default DivShowHide
