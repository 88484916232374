import React from 'react'
import PropTypes from 'prop-types'

const AlertModal = ({ t, closeAction, title, message, setAutofocusDOMRef }) => {
	return (
		<div className="modal-content">
			<div className="modal-header">
				<h5 className="modal-title">{title}</h5>
				<button type="button" className="close" aria-label="Close" onClick={closeAction}>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="modal-body">
				<p>{message}</p>
			</div>
			<div className="modal-footer">
				<button type="button" className="default" onClick={closeAction} ref={setAutofocusDOMRef}>{t('modal.actions.close')}</button>
			</div>
		</div>
	)
}

AlertModal.propTypes = {
	closeAction: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	setAutofocusDOMRef: PropTypes.func.isRequired,
}

export default AlertModal
