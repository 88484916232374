import { USERS } from '~/actions/types'

const initialState = {
	loading: false,
	details: {},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case USERS.CREATION_REQUEST:
		case USERS.DETAILS_REQUEST: {
			return {
				...initialState,
				loading: true,
			}
		}

		case USERS.CREATION_SUCCESS:
		case USERS.DETAILS_SUCCESS: {
			const { user: details } = action
			return {
				...state,
				loading: false,
				details,
			}
		}

		case USERS.CREATION_FAILURE:
		case USERS.DETAILS_FAILURE: {
			return initialState
		}

		default:
			return state
	}
}
