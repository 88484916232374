import React from 'react'
import classNames from 'classnames'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { default as modalTypes } from './modal-types'
import { cancelAction, confirmAction, closeAction, promptValueAction } from '~/actions/modals'

const MODAL_TYPES = {
	'alert': modalTypes.alertModal,
	'confirm': modalTypes.confirmModal,
	'prompt': modalTypes.promptModal,
	'demo': modalTypes.demoModal,
}

class ModalContainer extends React.PureComponent
{
	render() {
		const { t, modals, cancelAction, confirmAction, closeAction, promptValueAction } = this.props
		return (
			<>
				{ modals.map(({ modalProps, modalType }) => {
					const SpecifiedModal = MODAL_TYPES[modalType]
					return (
						<ReactModal
							key={modalProps.uid}
							isOpen={true}
							onRequestClose={cancelAction}
							contentLabel={modalProps.title}
							ariaHideApp={false}
							overlayClassName="modal-overlay"
							bodyOpenClassName="modal-open"
							className={classNames('modal-container', modalProps.severity, `modal-type-${modalType}`)}
						>
							<SpecifiedModal
								t={t}
								cancelAction={cancelAction}
								confirmAction={confirmAction}
								closeAction={closeAction}
								promptValueAction={promptValueAction}
								{...modalProps}
							/>
						</ReactModal>
					)
				})}
			</>
		)
	}
}

const mapStateToProps = ({ modals }) => ({ modals })

const mapDispatchToProps = dispatch => ({
	cancelAction: (...params) => dispatch(cancelAction(...params)),
	confirmAction: (...params) => dispatch(confirmAction(...params)),
	closeAction: (...params) => dispatch(closeAction(...params)),
	promptValueAction: (...params) => dispatch(promptValueAction(...params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(
	withTranslation()(ModalContainer)
)
