import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Switch = ({ enabled, onChanged, rounded }) => (
	<label className="switch">
		<input type="checkbox" onChange={onChanged} checked={enabled} />
		<span className={classNames('slider', { rounded })}></span>
	</label>
)

Switch.propTypes = {
	enabled: PropTypes.bool.isRequired,
	onChanged: PropTypes.func.isRequired,
	rounded: PropTypes.bool,
}

export default Switch
