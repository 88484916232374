import { CONSENT_AUTHENTICATION } from '~/actions/types'

///////////////////////////////////////////////////////////////////////

export const prepareLoginRequest = (challenge) => ({
	type: CONSENT_AUTHENTICATION.PREPARE_LOGIN_REQUEST,
	challenge,
})

export const prepareLoginFailure = (error) => ({
	type: CONSENT_AUTHENTICATION.PREPARE_LOGIN_FAILURE,
	error,
})

export const prepareLoginSuccess = (client) => ({
	type: CONSENT_AUTHENTICATION.PREPARE_LOGIN_SUCCESS,
	client,
})

///////////////////////////////////////////////////////////////////////

export const loginRequest = (challenge, email, password, remember) => ({
	type: CONSENT_AUTHENTICATION.LOGIN_REQUEST,
	challenge,
	email,
	password,
	remember,
})

export const loginFailure = (error) => ({
	type: CONSENT_AUTHENTICATION.LOGIN_FAILURE,
	error,
})

export const loginSuccess = () => ({
	type: CONSENT_AUTHENTICATION.LOGIN_SUCCESS,
})

///////////////////////////////////////////////////////////////////////
