import service from '~/api/service'
import authHeaders from '~/api/auth-header'

const apiResultCheck = (response) => {
	if (!response.success) {
		throw new Error(response.message)
	}
	return response
}

export const loginRequestApi = (email, password) => service.post(`/admin/login`, {
	json: { email, password },
})

export const applicationsListRequestApi = () => service.get(`/admin/applications`, {
	headers: authHeaders(),
})
.then(apiResultCheck)

export const createApplicationRequestApi = (name, flowType) => service.post(`/admin/applications`, {
	headers: authHeaders(),
	json: {
		name,
		flowType,
	},
})
.then(apiResultCheck)

export const applicationDetailsRequestApi = (clientId) => service.get(`/admin/application/${clientId}`, {
	headers: authHeaders(),
})
.then(apiResultCheck)

export const applicationDeleteRequestApi = (clientId) => service.delete(`/admin/application/${clientId}`, {
	headers: authHeaders(),
})
.then(apiResultCheck)

export const rotateSecretApplicationRequestApi = (clientId) => service.post(`/admin/application/${clientId}/rotate-key`, {
	headers: authHeaders(),
	json: {},
})
.then(apiResultCheck)

export const updateApplicationDetailsRequestApi = (clientId, data) => service.put(`/admin/application/${clientId}`, {
	headers: authHeaders(),
	json: {
		...data,
	},
})
.then(apiResultCheck)

export const usersListRequestApi = () => service.get(`/admin/users`, {
	headers: authHeaders(),
})
.then(apiResultCheck)

export const createUserRequestApi = (email, password, passwordRepeat) => service.post(`/admin/users`, {
	headers: authHeaders(),
	json: {
		email,
		password,
		passwordRepeat,
	},
})
.then(apiResultCheck)

export const userDetailsRequestApi = (userId) => service.get(`/admin/user/${userId}`, {
	headers: authHeaders(),
})
.then(apiResultCheck)

export const userDeleteRequestApi = (userId) => service.delete(`/admin/user/${userId}`, {
	headers: authHeaders(),
})
.then(apiResultCheck)

export const updateUserDetailsRequestApi = (userId, data) => service.put(`/admin/user/${userId}`, {
	headers: authHeaders(),
	json: {
		...data,
	},
})
.then(apiResultCheck)

export const blockUserRequestApi = (userId, state) => service.post(`/admin/user/${userId}/block`, {
	headers: authHeaders(),
	json: {
		state,
	},
})
.then(apiResultCheck)

export const updateUserPasswordRequestApi = (userId, password, passwordRepeat) => service.put(`/admin/user/${userId}/change-password`, {
	headers: authHeaders(),
	json: {
		password,
		passwordRepeat,
	},
})
.then(apiResultCheck)

export const scopesListRequestApi = () => service.get(`/admin/scopes`, {
	headers: authHeaders(),
})
.then(apiResultCheck)

export const createScopeRequestApi = (data) => service.post(`/admin/scopes`, {
	headers: authHeaders(),
	json: data,
})
.then(apiResultCheck)

export const scopeDeleteRequestApi = (scopeId) => service.delete(`/admin/scopes/${scopeId}`, {
	headers: authHeaders(),
})
.then(apiResultCheck)

export const updateScopeRequestApi = (scopeId, data) => service.put(`/admin/scopes/${scopeId}`, {
	headers: authHeaders(),
	json: data,
})
.then(apiResultCheck)
