import { APPLICATIONS } from '~/actions/types'

const initialState = {
	loading: false,
	list: [],
}

export default (state = initialState, action) => {
	switch (action.type) {
		case APPLICATIONS.LIST_REQUEST: {
			return {
				...initialState,
				loading: true,
			}
		}

		case APPLICATIONS.LIST_SUCCESS: {
			const { list } = action
			return {
				...state,
				loading: false,
				list,
			}
		}

		case APPLICATIONS.LIST_FAILURE: {
			return initialState
		}

		default:
			return state
	}
}
