import { CONSENT_ASK } from '~/actions/types'

///////////////////////////////////////////////////////////////////////

export const prepareAskRequest = (challenge) => ({
	type: CONSENT_ASK.PREPARE_ASK_REQUEST,
	challenge,
})

export const prepareAskFailure = (error) => ({
	type: CONSENT_ASK.PREPARE_ASK_FAILURE,
	error,
})

export const prepareAskSuccess = () => ({
	type: CONSENT_ASK.PREPARE_ASK_SUCCESS,
})

export const prepareAskSuccessWithData = (requested_scope, requested_scope_descriptions, client, user={}) => ({
	type: CONSENT_ASK.PREPARE_ASK_SUCCESS_WITH_DATA,
	requested_scope,
	requested_scope_descriptions,
	client,
	user,
})

///////////////////////////////////////////////////////////////////////

export const askRequest = (challenge, acceptConsent, grantScopes, remember) => ({
	type: CONSENT_ASK.ASK_REQUEST,
	challenge,
	acceptConsent,
	grantScopes,
	remember,
})

export const askFailure = (error) => ({
	type: CONSENT_ASK.ASK_FAILURE,
	error,
})

export const askSuccess = () => ({
	type: CONSENT_ASK.ASK_SUCCESS,
})

///////////////////////////////////////////////////////////////////////
