import service from '~/api/service'

const apiResultCheck = (response) => {
	if (!response.success) {
		throw new Error(response.message)
	}
	return response
}

export const userEmailVerificationRequestApi = (id, token) => service.post(`/u/email-verification`, {
	json: {
		id,
		token,
	},
})
.then(apiResultCheck)
