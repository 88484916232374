import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ id, name, checked = false, disabled, onChange }) => (
	<label className="checkbox-container" htmlFor={id}>
		<input type="checkbox" id={id} name={name} checked={checked} onChange={onChange} disabled={disabled} readOnly={disabled} />
		<span className="checkmark"></span>
	</label>

)

Checkbox.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
}

export default Checkbox
