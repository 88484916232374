import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const CenteredPanel = ({ className, children }) => (
	<div className={classNames('centered-panel', className)}>
		{ children }
	</div>
)

CenteredPanel.propTypes = {
	className: PropTypes.string,
}

export default CenteredPanel
