import React from 'react'
import PropTypes from 'prop-types'

const DemoModal = ({ closeAction, title, message, setAutofocusDOMRef }) => {
	return (
		<div className="modal-content">
			<div className="modal-header">
				<h5 className="modal-title">{title}</h5>
				<button type="button" className="close" aria-label="Close" onClick={closeAction}>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="modal-body">
				<p>{message}</p>
			</div>
			<div className="modal-footer">
				<button type="button" className="default" onClick={closeAction} ref={setAutofocusDOMRef}>Def</button>
				<button type="button" className="warning" onClick={closeAction}>War</button>
				<button type="button" className="danger" onClick={closeAction}>Dan</button>
				<button type="button" className="success" onClick={closeAction}>Suc</button>
				<button type="button" className="info" onClick={closeAction}>Inf</button>
			</div>
		</div>
	)
}

DemoModal.propTypes = {
	closeAction: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	setAutofocusDOMRef: PropTypes.func.isRequired,
}

export default DemoModal
