import { takeEvery, put, call } from 'redux-saga/effects'

import * as notification from '~/actions/notifications'
import { CONSENT_ASK } from '~/actions/types'
import handleRequestError from '~/api/handle-request-error'
import { prepareAskRequestApi, askRequestApi } from '~/api/consent'
import {
	prepareAskSuccess,
	prepareAskSuccessWithData,
	prepareAskFailure,
	askSuccess,
	askFailure,
} from '~/actions/consent/ask'

function* onPrepareAskRequest({ challenge }) {
	try {
		const { redirect_to, requested_scope, requested_scope_descriptions, client, user } = yield call(prepareAskRequestApi, challenge)

		if (redirect_to) {
			yield put(prepareAskSuccess())
			window.location = redirect_to
		}
		else if (requested_scope && client) {
			yield put(prepareAskSuccessWithData(requested_scope, requested_scope_descriptions, client, user))
		}
		else {
			throw new Error('Unknown state exception')
		}
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(prepareAskFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onAskRequest({ challenge, acceptConsent, grantScopes, remember }) {
	try {
		const { redirect_to } = yield call(askRequestApi, challenge, acceptConsent, grantScopes, remember)

		yield put(askSuccess())

		if (redirect_to) {
			window.location = redirect_to
		}
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(askFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

export default function*() {
	yield takeEvery(CONSENT_ASK.PREPARE_ASK_REQUEST, onPrepareAskRequest)
	yield takeEvery(CONSENT_ASK.ASK_REQUEST, onAskRequest)
}
