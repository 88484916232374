import React from 'react'

import AlertModalComponent from '~/components/modal-types/AlertModal'

class AlertModal extends React.PureComponent
{
	autofocusDOMRef = null
	state = {}

	componentDidMount() {
		if (this.autofocusDOMRef) {
			setImmediate(() => this.autofocusDOMRef.focus())
		}
	}

	setAutofocusDOMRef = (element) => {
		this.autofocusDOMRef = element
	}

	render() {
		const { t, closeAction, title, message } = this.props
		return (
			<AlertModalComponent
				t={t}
                closeAction={closeAction}
				title={title}
				message={message}
				setAutofocusDOMRef={this.setAutofocusDOMRef}
			/>
		)
	}
}

export default AlertModal
