import React from 'react'

import SidebarComponent from '~/components/Sidebar'

import sidebarData from '~/data/sidebar'

class Sidebar extends React.PureComponent
{
	render() {
		return (
			<nav className="sidebar">
				<SidebarComponent items={sidebarData} />
			</nav>
		)
	}
}
export default Sidebar