import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import DivShowHide from '~/components/DivShowHide'
import { creationRequest } from '~/actions/admin/applications'

const DivShowHideTitle = (
	<>
		<i className="icon-lifebuoy" aria-hidden="true"></i>
		{' '}
		Need help to choose an OAuth2 Flow ?
	</>
)

class ApplicationCreation extends React.Component
{
	state = {
		name: '',
	}

	createApplication = (flowType) => {
		const { name } = this.state
		if (!name || !flowType) {
			return
		}
		const { creationRequest } = this.props
		creationRequest(name, flowType)
	}

	onSubmit = (e) => {
		e.preventDefault()
		return false
	}

	handleChange = (e) => {
		const { name, value } = e.target
		this.setState({ [name]: value })
	}

	render() {
		const { name } = this.state
		return (
			<>
				<h1 className="content-title">Create application</h1>
				<DivShowHide title={DivShowHideTitle}>
					<div className="paragraph">
						<blockquote>
							<p><img src="https://lh4.googleusercontent.com/aKscXYqfTuveFHcDBX-s6ph8hVAHucACgqMm4IagjzxfQtZzhRZmYHkCnJtKK6-W_4TVY73ueWm1doxt8zX_40Zn4ajkO5pbNx1kZ05XmNteIHvM48W42y43UMlTKuFdGqaUwdik" alt="How to choose an OAuth2 flow" /></p>
							<p>Source (fr): <a href="https://blog.octo.com/securiser-une-api-rest-tout-ce-quil-faut-savoir/" target="_blank" rel="noopener noreferrer">https://blog.octo.com/securiser-une-api-rest-tout-ce-quil-faut-savoir/</a></p>
							<p>Source (en): <a href="https://auth0.com/docs/api-auth/which-oauth-flow-to-use" target="_blank" rel="noopener noreferrer">https://auth0.com/docs/api-auth/which-oauth-flow-to-use</a></p>
							<div className="divider"></div>
						</blockquote>
					</div>
				</DivShowHide>

				<form method="POST" onSubmit={this.onSubmit}>
					<div>
						<label>Name</label>
						<input type="text" name="name" placeholder="Application name" autoFocus required value={name} onChange={this.handleChange} />
						<p>You can change the application name later in the application settings.</p>
					</div>
					<div>
						<label>Choose an application type</label>
						<table>
							<tbody>
								<tr>
									<td className="application-type">
										<h4>Native</h4>
										<blockquote>
											<p>Mobile, desktop, CLI and smart device apps running natively.</p>
											<p>e.g.: iOS, Electron, Apple TV apps</p>
											<p>OAuth2 Flow: Authorization Code Flow with PKCE (Authorization Code Grant using Proof Key for Code Exchange)</p>
										</blockquote>
										<button className={classNames('choose-application-type-button', 'success')} onClick={() => this.createApplication('native')}>Choose this one</button>
									</td>
									<td className="application-type">
										<h4>Single Page Web Applications</h4>
										<blockquote>
											<p>A JavaScript front-end app that uses an API.</p>
											<p>e.g.: Angular.JS + NodeJS</p>
											<p>OAuth2 Flow: Implicit Grant</p>
										</blockquote>
										<button className={classNames('choose-application-type-button', 'success')} onClick={() => this.createApplication('single-page')}>Choose this one</button>
									</td>
								</tr>
								<tr>
									<td className="application-type">
										<h4>Regular Web Applications</h4>
										<blockquote>
											<p>Traditional web app using redirects.</p>
											<p>e.g: Java, ASP.NET</p>
											<p>OAuth2 Flow: Authorization Code Grant</p>
										</blockquote>
										<button className={classNames('choose-application-type-button', 'success')} onClick={() => this.createApplication('regular-web')}>Choose this one</button>
									</td>
									<td className="application-type">
										<h4>Machine to Machine Applications</h4>
										<blockquote>
											<p>CLIs, daemons or serices running on your backend.</p>
											<p>e.g: Shell script</p>
											<p>OAuth2 Flow: Client Credentials Grant</p>
										</blockquote>
										<button className={classNames('choose-application-type-button', 'success')} onClick={() => this.createApplication('machine-to-machine')}>Choose this one</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</form>
			</>
		)
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		creationRequest,
	}, dispatch)
)

export default connect(null, mapDispatchToProps)(ApplicationCreation)
