import alertModal from '~/containers/modal-types/AlertModal'
import confirmModal from '~/containers/modal-types/ConfirmModal'
import promptModal from '~/containers/modal-types/PromptModal'
import demoModal from '~/containers/modal-types/DemoModal'

const modalTypes = {
	alertModal,
	confirmModal,
	promptModal,
	demoModal,
}

export default modalTypes
