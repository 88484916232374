import shortid from 'shortid'

import { MODALS } from './types'

export const show = ({ modalProps, modalType }) => ({
	type: MODALS.SHOW,
	modalProps: {
		uid: shortid.generate(),
		open: true,
		severity: 'default',
		...modalProps,
	},
	modalType,
})

export const close = (uid = undefined) => ({
	type: MODALS.CLOSE,
	uid,
})

export const closeAll = () => ({
	type: MODALS.CLOSE_ALL,
})

export const confirmation = (title, message) => show({
	modalProps: {
		title,
		message,
	},
	modalType: 'confirm',
})

export const alert = (title, message) => show({
	modalProps: {
		title,
		message,
	},
	modalType: 'alert',
})

export const prompt = (title, message, fields=[]) => show({
	modalProps: {
		title,
		message,
		fields,
	},
	modalType: 'prompt',
})

export const cancelAction = () => ({
	type: MODALS.ACTION_CANCEL,
})

export const confirmAction = () => ({
	type: MODALS.ACTION_CONFIRM,
})

export const closeAction = () => ({
	type: MODALS.ACTION_CLOSE,
})

export const promptValueAction = (values) => ({
	type: MODALS.ACTION_PROMPT_VALUE,
	values,
})
