import React from 'react'
import PropTypes from 'prop-types'

import PromptModalComponent from '~/components/modal-types/PromptModal'

class PromptModal extends React.PureComponent
{
	autofocusDOMRef = null
	state = {}

	onInputChange = (e) => {
		const { target, target: { name } } = e
		const value = target.type === 'checkbox' ? target.checked : target.value
		this.setState({ [name]: value })
	}

	confirmClickAction = () => {
		const { promptValueAction } = this.props
		promptValueAction(this.state)
	}

	componentDidMount() {
		const { fields } = this.props

		const defaultState = fields.reduce((acc, val) => {
			acc[val.name] = val.defaultValue || ''
			return acc
		}, {})

		if (Object.keys(defaultState).length) {
			this.setState(defaultState)
		}

		if (this.autofocusDOMRef) {
			setImmediate(() => this.autofocusDOMRef.focus())
		}
	}

	setAutofocusDOMRef = (element) => {
		this.autofocusDOMRef = element
	}

	render() {
		const { t, cancelAction, title, message, fields } = this.props
		return (
			<PromptModalComponent
				t={t}
				cancelAction={cancelAction}
				title={title}
				message={message}
				fields={fields}
				onInputChange={this.onInputChange}
				confirmClickAction={this.confirmClickAction}
				setAutofocusDOMRef={this.setAutofocusDOMRef}
			/>
		)
	}
}

PromptModal.propTypes = {
	promptValueAction: PropTypes.func.isRequired,
}

export default PromptModal
