import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { listRequest } from '~/actions/admin/users'

const StatusIcon = ({ active, name, iconDefault, iconActive, titleDefault, titleActive }) => {
	return <i className={classNames(name, `icon-${active ? iconActive : iconDefault}`, { active })} aria-hidden="true" title={active ? titleActive : titleDefault}></i>
}

class UserList extends React.Component
{
	componentDidMount() {
		const { listRequest } = this.props
		listRequest()
	}

	render() {
		const { users, loading } = this.props
		return (
			<>
				<h1 className="content-title">
					User List <Link to="/admin/users/new" className="button default btn-create-new-above-list">+ New user</Link>
				</h1>

				{ loading && <p>Loading...</p> }

				{ users.length ? (
					<table className="user-list">
						<thead>
							<tr>
								<th></th>
								<th>Nickname</th>
								<th>Name</th>
								<th>Email</th>
								<th className="no-padding-horiz">Login count</th>
								<th className="status"></th>
							</tr>
						</thead>
						<tbody>
							{ users.map(user => (
								<tr key={user.id}>
									<td className="profile-picture"><img src={user.picture_url || '/static/user-default.svg'} alt="user default" /></td>
									<td><Link to={`/admin/user/${user.id}`}>{user.nickname}</Link></td>
									<td>{user.name}</td>
									<td><Link to={`/admin/user/${user.id}`}>{user.email}</Link></td>
									<td>{user.loginCount}</td>
									<td className="status">
										<StatusIcon
											active={user.verifiedAt !== null}
											name="verified-status"
											iconDefault="shield"
											iconActive="shield_ok"
											titleDefault="Not verified yet"
											titleActive="Verified"
										/>
										<StatusIcon
											active={user.blockedAt !== null}
											name="blocked-status"
											iconDefault="check_circle"
											iconActive="highlight_remove"
											titleDefault="Accessible"
											titleActive="Blocked"
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<p>No user found.</p>
				)}
			</>
		)
	}
}

UserList.propTypes = {
	users: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
}

UserList.defaultProps = {
	users: [],
	loading: false,
}

const mapStateToProps = ({ admin }) => {
	const { users } = admin
	return {
		loading: users.loading,
		users: users.list,
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		listRequest,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
