import jwtDecode from 'jwt-decode'

function getAdminUser() {
	try {
		const token = localStorage.getItem('token')
		if (token) {
			const user = jwtDecode(token)
			return user
		}
		return undefined
	}
	catch {
		return undefined
	}
}

export default getAdminUser
