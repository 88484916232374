import { takeEvery, put, call, fork, take } from 'redux-saga/effects'

import i18n from '~/i18n'
import history from '~/utils/history'
import { SCOPES } from '~/actions/types'
import * as notification from '~/actions/notifications'
import handleRequestError from '~/api/handle-request-error'
import {
	scopesListRequestApi,
	createScopeRequestApi,
	scopeDeleteRequestApi,
	updateScopeRequestApi,
} from '~/api/admin'
import {
	listSuccess,
	listFailure,
	creationSuccess,
	creationFailure,
	deleteSuccess,
	deleteFailure,
	updateSuccess,
	updateFailure,
} from '~/actions/admin/scopes'
import { askConfirmationSaga, askPromptSaga } from '~/utils/saga'

function* onListRequest() {
	try {
		const { scopes } = yield call(scopesListRequestApi)
		yield put(listSuccess(scopes))
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(listFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onCreationRequest() {
	const prompt = yield call(askPromptSaga, {
		title: 'Create a new scope',
		message: null,
		fields: [
			{ label: 'ID:', name: 'id', type: 'text', placeholder: 'project.name', className: null },
			{ label: 'Name:', name: 'name', type: 'text', placeholder: 'name', className: null },
			{ label: 'Description:', name: 'description', type: 'text', placeholder: 'description', className: null },
		],
	})

	if (!prompt) {
		return
	}

	const { id, name, description } = prompt

	try {
		yield call(createScopeRequestApi, {
			id,
			name,
			description,
		})

		yield put(creationSuccess())

		yield put(notification.success({
			title: 'Success',
			message: 'Scope successfully created',
		}))

		yield call(onListRequest)
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(creationFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onUpdateRequest({ scope }) {
	const {
		name: baseName,
		description: baseDescription,
	} = scope

	const prompt = yield call(askPromptSaga, {
		title: `Update '${baseName}' scope`,
		message: null,
		fields: [
			{ label: 'Name:', name: 'name', type: 'text', placeholder: baseName, defaultValue: baseName, className: null },
			{ label: 'Description:', name: 'description', type: 'text', placeholder: baseDescription, defaultValue: baseDescription, className: null },
		],
	})

	if (!prompt) {
		return
	}

	const {
		name: newName,
		description: newDescription,
	} = prompt

	if ((baseName === newName) && (baseDescription === newDescription)) {
		yield put(updateSuccess())
		return
	}

	const newScope = {
		...scope,
		name: newName,
		description: newDescription,
	}
	const { id, ...data } = newScope

	try {
		yield call(updateScopeRequestApi, id, data)

		yield put(updateSuccess())

		yield put(notification.success({
			title: 'Success',
			message: 'Scope successfully updated',
		}))

		yield call(onListRequest)
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(updateFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onDeleteRequest() {
	while (true) {
		const { scope } = yield take(SCOPES.DELETE_REQUEST)

		const message = i18n.t('modal.ask-delete-scope-confirmation', { name: scope.name })
		const confirmed = yield call(askConfirmationSaga, message)
		if (!confirmed) {
			continue
		}

		try {
			yield call(scopeDeleteRequestApi, scope.id)

			yield put(deleteSuccess())

			yield put(notification.success({
				title: 'Success',
				message: 'Scope successfully deleted',
			}))

			yield call(onListRequest)
		}
		catch (err) {
			const errDescr = yield call(handleRequestError, err)
			yield put(deleteFailure(errDescr))
			yield put(notification.error({
				title: 'Error',
				message: errDescr,
			}))
		}
	}
}

export default function*() {
	yield takeEvery(SCOPES.LIST_REQUEST, onListRequest)
	yield takeEvery(SCOPES.CREATION_REQUEST, onCreationRequest)
	yield takeEvery(SCOPES.UPDATE_REQUEST, onUpdateRequest)
	yield fork(onDeleteRequest)
}
