import { takeEvery, put, call, fork, take } from 'redux-saga/effects'

import i18n from '~/i18n'
import history from '~/utils/history'
import { USERS } from '~/actions/types'
import * as notification from '~/actions/notifications'
import handleRequestError from '~/api/handle-request-error'
import {
	usersListRequestApi,
	createUserRequestApi,
	userDetailsRequestApi,
	userDeleteRequestApi,
	updateUserDetailsRequestApi,
	blockUserRequestApi,
	updateUserPasswordRequestApi,
} from '~/api/admin'
import {
	listSuccess,
	listFailure,
	creationSuccess,
	creationFailure,
	detailsSuccess,
	detailsFailure,
	deleteSuccess,
	deleteFailure,
	updateDetailsSuccess,
	updateDetailsFailure,
	blockUserSuccess,
	blockUserFailure,
	changeUserPasswordSuccess,
	changeUserPasswordFailure,
} from '~/actions/admin/users'
import { askConfirmationSaga, askPromptSaga } from '~/utils/saga'

function* onListRequest() {
	try {
		const { users } = yield call(usersListRequestApi)
		yield put(listSuccess(users))
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(listFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onCreationRequest({ email, password, passwordRepeat }) {
	try {
		const { user } = yield call(createUserRequestApi, email, password, passwordRepeat)
		yield put(creationSuccess(user))
		history.push(`/admin/user/${user.id}`)
	}
	catch (err) {
		let errDescr = yield call(handleRequestError, err)
		yield put(creationFailure(errDescr))
		if (Array.isArray(errDescr)) {
			errDescr = errDescr.join('. Reason: ')
		}
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onDetailsRequest({ userId }) {
	try {
		const { user } = yield call(userDetailsRequestApi, userId)
		yield put(detailsSuccess(user))
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(detailsFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onDeleteRequest() {
	while (true) {
		const { userId, email } = yield take(USERS.DELETE_REQUEST)

		const message = i18n.t('modal.ask-delete-user-confirmation', { email })
		const confirmed = yield call(askConfirmationSaga, message)
		if (!confirmed) {
			continue
		}

		try {
			yield call(userDeleteRequestApi, userId)
			yield put(deleteSuccess())
			history.push(`/admin/users`)
			yield put(notification.success({
				title: 'Success',
				message: 'User successfully deleted',
			}))
		}
		catch (err) {
			const errDescr = yield call(handleRequestError, err)
			yield put(deleteFailure(errDescr))
			yield put(notification.error({
				title: 'Error',
				message: errDescr,
			}))
		}
	}
}

function* onUpdateDetailsRequest({ userId, data }) {
	try {
		yield call(updateUserDetailsRequestApi, userId, data)
		yield put(updateDetailsSuccess())
		history.push(`/admin/users`)
		yield put(notification.success({
			title: 'Success',
			message: 'User details successfully updated',
		}))
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(updateDetailsFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onBlockUserRequest() {
	while (true) {
		const { userId, email, state } = yield take(USERS.BLOCK_USER_REQUEST)

		const message = i18n.t(state ? 'modal.ask-block-user-confirmation' : 'modal.ask-unblock-user-confirmation', { email })
		const confirmed = yield call(askConfirmationSaga, message)
		if (!confirmed) {
			continue
		}

		try {
			yield call(blockUserRequestApi, userId, state)
			yield put(blockUserSuccess())
			history.push(`/admin/users`)
			yield put(notification.success({
				title: 'Success',
				message: `User successfully ${state ? 'blocked' : 'unblocked'}`,
			}))
		}
		catch (err) {
			const errDescr = yield call(handleRequestError, err)
			yield put(blockUserFailure(errDescr))
			yield put(notification.error({
				title: 'Error',
				message: errDescr,
			}))
		}
	}
}

function* onChangeUserPasswordRequest() {
	while (true) {
		const { userId } = yield take(USERS.CHANGE_USER_PASSWORD_REQUEST)

		const prompt = yield call(askPromptSaga, {
			title: 'Choose a new password',
			message: null,
			fields: [
				{ label: 'Password:', name: 'password', type: 'password', placeholder: '******', className: null },
				{ label: 'Repeat password:', name: 'passwordRepeat', type: 'password', placeholder: '******', className: null },
			],
		})
	
		if (!prompt) {
			continue
		}
	
		const { password, passwordRepeat } = prompt
	
		if (password.length === 0) {
			yield put(notification.error({
				title: 'Error',
				message: 'Password can\'t be empty!',
			}))
			continue
		}

		if (password !== passwordRepeat) {
			yield put(notification.error({
				title: 'Error',
				message: 'Passwords are not the same!',
			}))
			continue
		}
	
		try {
			yield call(updateUserPasswordRequestApi, userId, password, passwordRepeat)
			yield put(changeUserPasswordSuccess())
			yield put(notification.success({
				title: 'Success',
				message: 'User password successfully changed.',
			}))
		}
		catch (err) {
			let errDescr = yield call(handleRequestError, err)
			yield put(changeUserPasswordFailure(errDescr))
			if (Array.isArray(errDescr)) {
				errDescr = errDescr.join('. Reason: ')
			}
			yield put(notification.error({
				title: 'Error',
				message: errDescr,
			}))
		}
	}
}

export default function*() {
	yield takeEvery(USERS.LIST_REQUEST, onListRequest)
	yield takeEvery(USERS.CREATION_REQUEST, onCreationRequest)
	yield takeEvery(USERS.DETAILS_REQUEST, onDetailsRequest)
	yield fork(onDeleteRequest)
	yield takeEvery(USERS.UPDATE_DETAILS_REQUEST, onUpdateDetailsRequest)
	yield fork(onBlockUserRequest)
	yield fork(onChangeUserPasswordRequest)
}
