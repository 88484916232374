import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'

import Checkbox from '~/components/Checkbox'
import CenteredPanel from '~/components/CenteredPanel'
import { prepareLoginRequest, loginRequest } from '~/actions/consent/authentication'
import { getAcronym } from '~/utils'

class Login extends React.Component
{
	state = {
		email: '',
		password: '',
		remember: false,
		submitted: false,
	}

	componentDidMount() {
		const { location, prepareLoginRequest } = this.props
		const { login_challenge } = queryString.parse(location.search)
		this.login_challenge = login_challenge
		if (login_challenge) {
			prepareLoginRequest(login_challenge)
		}
		else {
			this.setState({
				fatalError: 'An error occured.',
			})
		}
	}

	handleChange = (e) => {
		const { name, type } = e.target
		const value = (type === 'checkbox') ? e.target.checked : e.target.value
		this.setState({ [name]: value })
	}

	handleSubmit = (e) => {
		e.preventDefault()

		if (this.login_challenge) {
			const { email, password, remember } = this.state
			this.setState({ submitted: true })

			if (email && password) {
				const { loginRequest } = this.props
				loginRequest(this.login_challenge, email, password, remember)
			}
		}
	}

	render() {
		const { loading, client } = this.props
		const { fatalError, email, password, remember, submitted } = this.state

		if (fatalError) {
			return <p>{fatalError}</p>
		}

		const appLogo = client.logo_uri || ''
		let appName = client.client_name || ''
		let appAcronym = '?'

		if (!appLogo && appName) {
			appAcronym = getAcronym(appName)
		}
		else if (!appName) {
			appName = 'Unnamed App'
		}

		return (
			<div className="consent-login">
				<CenteredPanel className={classNames({ 'submitted-loading': submitted && loading })}>
					<div className="centered-panel-content">
						{ appLogo.length !== 0 ? (
							<div className="logo logo-image size-medium" style={{ backgroundImage: `url(${appLogo})` }}></div>
						) : (
							<div className="logo logo-acronym size-medium" title={appAcronym}></div>
						)}
						<div className="title">Welcome</div>
						<div className="sub-title">Log in to <strong>{appName}</strong> to continue</div>
						<form method="POST" onSubmit={this.handleSubmit}>
							<div className="fields">

								<div className="form-group">
									<label htmlFor="email-input" className="contains-svg">
										<svg viewBox="0 0 1024 1024"><path className="path1" d="M896 307.2h-819.2c-42.347 0-76.8 34.453-76.8 76.8v460.8c0 42.349 34.453 76.8 76.8 76.8h819.2c42.349 0 76.8-34.451 76.8-76.8v-460.8c0-42.347-34.451-76.8-76.8-76.8zM896 358.4c1.514 0 2.99 0.158 4.434 0.411l-385.632 257.090c-14.862 9.907-41.938 9.907-56.802 0l-385.634-257.090c1.443-0.253 2.92-0.411 4.434-0.411h819.2zM896 870.4h-819.2c-14.115 0-25.6-11.485-25.6-25.6v-438.566l378.4 252.267c15.925 10.618 36.363 15.925 56.8 15.925s40.877-5.307 56.802-15.925l378.398-252.267v438.566c0 14.115-11.485 25.6-25.6 25.6z"></path></svg>
									</label>
									<input type="text" id="email-input" placeholder="Email" name="email" value={email} onChange={this.handleChange} />
								</div>
								{ submitted && !email && (
									<div className="error-text">Email is required</div>
								)}

								<div className="form-group">
									<label htmlFor="pass-input" className="contains-svg">
										<svg viewBox="0 0 1024 1024"><path className="path1" d="M742.4 409.6h-25.6v-76.8c0-127.043-103.357-230.4-230.4-230.4s-230.4 103.357-230.4 230.4v76.8h-25.6c-42.347 0-76.8 34.453-76.8 76.8v409.6c0 42.347 34.453 76.8 76.8 76.8h512c42.347 0 76.8-34.453 76.8-76.8v-409.6c0-42.347-34.453-76.8-76.8-76.8zM307.2 332.8c0-98.811 80.389-179.2 179.2-179.2s179.2 80.389 179.2 179.2v76.8h-358.4v-76.8zM768 896c0 14.115-11.485 25.6-25.6 25.6h-512c-14.115 0-25.6-11.485-25.6-25.6v-409.6c0-14.115 11.485-25.6 25.6-25.6h512c14.115 0 25.6 11.485 25.6 25.6v409.6z"></path></svg>
									</label>
									<input type="password" id="pass-input" placeholder="Password" name="password" value={password} onChange={this.handleChange} />
								</div>
								{ submitted && !password && (
									<div className="error-text">Password is required</div>
								)}

								<div className="form-group">
									<Checkbox
										id="remember"
										name="remember"
										checked={remember}
										onChange={this.handleChange}
									/>{' '}<label htmlFor="remember">Remember me</label>
								</div>

							</div>
							<button type="submit" className="submit-button">Log in</button>
						</form>
						{/* <div className="additional-links">
							<a href="#">Forgot password?</a>
						</div> */}
					</div>
				</CenteredPanel>
				{ loading && (
					<img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="Loader" className="loader" />
				)}
			</div>
		)
	}
}

const mapStateToProps = ({ consent }) => {
	const { loading, client } = consent.authentication
	return {
		loading,
		client,
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		prepareLoginRequest,
		loginRequest,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Login)
