import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { creationRequest } from '~/actions/admin/users'

class UserCreation extends React.Component
{
	state = {
		email: '',
		password: '',
		passwordRepeat: '',
	}

	createUser = () => {
		const {
			email,
			password,
			passwordRepeat,
		} = this.state
		if (!email || !password || !passwordRepeat) {
			return
		}
		const { creationRequest } = this.props
		creationRequest(email, password, passwordRepeat)
	}

	onSubmit = (e) => {
		e.preventDefault()
		return false
	}

	handleChange = (e) => {
		const { name, value } = e.target
		this.setState({ [name]: value })
	}

	render() {
		const {
			email,
			password,
			passwordRepeat,
		} = this.state
		return (
			<>
				<h1 className="content-title">Create user</h1>

				<form method="POST" onSubmit={this.onSubmit}>
					<div>
						<label>Email</label>
						<input type="email" name="email" placeholder="User email" autoFocus required value={email} onChange={this.handleChange} />
					</div>
					<div>
						<label>Password</label>
						<input type="password" name="password" placeholder="******" required value={password} onChange={this.handleChange} />
					</div>
					<div>
						<label>Repeat password</label>
						<input type="password" name="passwordRepeat" placeholder="******" required value={passwordRepeat} onChange={this.handleChange} />
					</div>
					<button className="success" onClick={this.createUser}>Create</button>
				</form>
			</>
		)
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		creationRequest,
	}, dispatch)
)

export default connect(null, mapDispatchToProps)(UserCreation)
