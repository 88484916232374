import { MODALS } from '~/actions/types'

const initialState = []

export default (state = initialState, action) => {
	switch (action.type) {
		case MODALS.SHOW:
			const modal = {
				modalProps: action.modalProps,
				modalType: action.modalType,
			}
			return [
				...state,
				modal,
			]

		case MODALS.CLOSE:
			if (!action.uid) {
				action.uid = state.length ? state[state.length - 1].modalProps.uid : undefined
			}
			if (!action.uid) {
				return state
			}
			return state.filter(modal => {
				return modal.modalProps.uid !== action.uid
			})

		case MODALS.CLOSE_ALL:
			return []

		default:
			return state
	}
}
