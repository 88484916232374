import { USERS } from '~/actions/types'

export const listRequest = () => ({
	type: USERS.LIST_REQUEST,
})

export const listFailure = (error) => ({
	type: USERS.LIST_FAILURE,
	error,
})

export const listSuccess = (list) => ({
	type: USERS.LIST_SUCCESS,
	list,
})

export const creationRequest = (email, password, passwordRepeat) => ({
	type: USERS.CREATION_REQUEST,
	email,
	password,
	passwordRepeat,
})

export const creationFailure = (error) => ({
	type: USERS.CREATION_FAILURE,
	error,
})

export const creationSuccess = (user) => ({
	type: USERS.CREATION_SUCCESS,
	user,
})

export const detailsRequest = (userId) => ({
	type: USERS.DETAILS_REQUEST,
	userId,
})

export const detailsFailure = (error) => ({
	type: USERS.DETAILS_FAILURE,
	error,
})

export const detailsSuccess = (user) => ({
	type: USERS.DETAILS_SUCCESS,
	user,
})

export const deleteRequest = (userId, email) => ({
	type: USERS.DELETE_REQUEST,
	userId,
	email,
})

export const deleteFailure = (error) => ({
	type: USERS.DELETE_FAILURE,
	error,
})

export const deleteSuccess = () => ({
	type: USERS.DELETE_SUCCESS,
})

export const updateDetailsRequest = (userId, data) => ({
	type: USERS.UPDATE_DETAILS_REQUEST,
	userId,
	data,
})

export const updateDetailsFailure = (error) => ({
	type: USERS.UPDATE_DETAILS_FAILURE,
	error,
})

export const updateDetailsSuccess = () => ({
	type: USERS.UPDATE_DETAILS_SUCCESS,
})

export const blockUserRequest = (userId, email, state) => ({
	type: USERS.BLOCK_USER_REQUEST,
	userId,
	email,
	state,
})

export const blockUserFailure = (error) => ({
	type: USERS.BLOCK_USER_FAILURE,
	error,
})

export const blockUserSuccess = () => ({
	type: USERS.BLOCK_USER_SUCCESS,
})

export const changeUserPasswordRequest = (userId) => ({
	type: USERS.CHANGE_USER_PASSWORD_REQUEST,
	userId,
})

export const changeUserPasswordFailure = (error) => ({
	type: USERS.CHANGE_USER_PASSWORD_FAILURE,
	error,
})

export const changeUserPasswordSuccess = () => ({
	type: USERS.CHANGE_USER_PASSWORD_SUCCESS,
})
