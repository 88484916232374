import jwtDecode from 'jwt-decode'
import { takeEvery, put, call } from 'redux-saga/effects'

import history from '~/utils/history'
import { loginRequestApi } from '~/api/admin'
import { ADMIN_AUTHENTICATION } from '~/actions/types'
import * as notification from '~/actions/notifications'
import handleRequestError from '~/api/handle-request-error'
import { loginSuccess, loginFailure } from '~/actions/admin/authentication'

function* onLoginRequest({ email, password }) {
	try {
		const token = yield call(loginRequestApi, email, password)
		const user = jwtDecode(token)
		localStorage.setItem('token', token)
		yield put(loginSuccess(user))
		yield put(notification.success({
			title: 'Success',
			message: 'Successfully logged in',
		}))
		history.push('/admin')
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(loginFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function onLogout({ silenced }) {
	// remove user from local storage to log user out
	localStorage.removeItem('token')
	if (!silenced) {
		window.location.reload(true)
	}
}

export default function*() {
	yield takeEvery(ADMIN_AUTHENTICATION.LOGIN_REQUEST, onLoginRequest)
	yield takeEvery(ADMIN_AUTHENTICATION.LOGOUT, onLogout)
}
