import React from 'react'
import { css } from 'emotion'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const activeStyle = css`
	color: white !important;
	background-color: #3fb571 !important;
`

class ClickToCopyButton extends React.PureComponent
{
	state = {
		copied: false,
	}

	onCopy = (text, result) => {
		if (!result) {
			return
		}

		const { animationTimeout } = this.props
		this.setState({ copied: true })
		setTimeout(() => this.setState({ copied: false }), animationTimeout)
	}

	render() {
		const { text } = this.props
		const { copied } = this.state

		return (
			<CopyToClipboard
				text={text}
				onCopy={this.onCopy}
			>
				<span className={classNames('button', 'default', 'no-float', { [activeStyle]: copied })}>
					<i className="icon-content_copy" aria-hidden="true"></i>
				</span>
			</CopyToClipboard>
		)
	}
}

ClickToCopyButton.propTypes = {
	text: PropTypes.string.isRequired,
	animationTimeout: PropTypes.number.isRequired,
}

ClickToCopyButton.defaultProps = {
	animationTimeout: 1000,
}

export default ClickToCopyButton
