import { takeEvery, put, call, fork, take, race } from 'redux-saga/effects'

import i18n from '~/i18n'
import history from '~/utils/history'
import { APPLICATIONS, MODALS } from '~/actions/types'
import * as notification from '~/actions/notifications'
import handleRequestError from '~/api/handle-request-error'
import { confirmation, close as closeModal } from '~/actions/modals'
import {
	applicationsListRequestApi,
	createApplicationRequestApi,
	applicationDetailsRequestApi,
	applicationDeleteRequestApi,
	rotateSecretApplicationRequestApi,
	updateApplicationDetailsRequestApi,
} from '~/api/admin'
import {
	listSuccess,
	listFailure,
	creationSuccess,
	creationFailure,
	detailsSuccess,
	detailsFailure,
	deleteSuccess,
	deleteFailure,
	rotateSecretSuccess,
	rotateSecretFailure,
	updateDetailsSuccess,
	updateDetailsFailure,
} from '~/actions/admin/applications'
import { askConfirmationSaga } from '~/utils/saga'

function* onListRequest() {
	try {
		const { applications } = yield call(applicationsListRequestApi)
		yield put(listSuccess(applications))
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(listFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onCreationRequest({ name, flowType }) {
	try {
		const { application } = yield call(createApplicationRequestApi, name, flowType)
		yield put(creationSuccess(application))
		history.push(`/admin/application/${application.client_id}`)
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(creationFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onDetailsRequest({ clientId }) {
	try {
		const { application } = yield call(applicationDetailsRequestApi, clientId)
		yield put(detailsSuccess(application))
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(detailsFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

function* onDeleteRequest() {
	while (true) {
		const { clientId, clientName } = yield take(APPLICATIONS.DELETE_REQUEST)

		const message = i18n.t('modal.ask-delete-application-confirmation', { name: clientName })
		const confirmed = yield call(askConfirmationSaga, message)
		if (!confirmed) {
			continue
		}

		try {
			yield call(applicationDeleteRequestApi, clientId)
			yield put(deleteSuccess())
			history.push(`/admin/applications`)
			yield put(notification.success({
				title: 'Success',
				message: 'Application successfully deleted',
			}))
		}
		catch (err) {
			const errDescr = yield call(handleRequestError, err)
			yield put(deleteFailure(errDescr))
			yield put(notification.error({
				title: 'Error',
				message: errDescr,
			}))
		}
	}
}

function* onRotateSecretRequest() {
	while (true) {
		const { clientId, clientName } = yield take(APPLICATIONS.ROTATE_SECRET_REQUEST)

		const message = i18n.t('modal.ask-secret-rotation-confirmation', { name: clientName })
		const confirmed = yield call(askConfirmationSaga, message)
		if (!confirmed) {
			continue
		}

		try {
			const { application } = yield call(rotateSecretApplicationRequestApi, clientId)
			yield put(rotateSecretSuccess(application))
			history.push(`/admin/application/${application.client_id}`)
			yield put(notification.success({
				title: 'Success',
				message: 'Secret successfully rotated',
			}))
		}
		catch (err) {
			const errDescr = yield call(handleRequestError, err)
			yield put(rotateSecretFailure(errDescr))
			yield put(notification.error({
				title: 'Error',
				message: errDescr,
			}))
		}
	}
}

function* onUpdateDetailsRequest({ clientId, data }) {
	try {
		yield call(updateApplicationDetailsRequestApi, clientId, data)
		yield put(updateDetailsSuccess())
		history.push(`/admin/applications`)
		yield put(notification.success({
			title: 'Success',
			message: 'Application details successfully updated',
		}))
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(updateDetailsFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

export default function*() {
	yield takeEvery(APPLICATIONS.LIST_REQUEST, onListRequest)
	yield takeEvery(APPLICATIONS.CREATION_REQUEST, onCreationRequest)
	yield takeEvery(APPLICATIONS.DETAILS_REQUEST, onDetailsRequest)
	yield fork(onDeleteRequest)
	yield fork(onRotateSecretRequest)
	yield takeEvery(APPLICATIONS.UPDATE_DETAILS_REQUEST, onUpdateDetailsRequest)
}
