import { ADMIN_AUTHENTICATION } from '~/actions/types'

///////////////////////////////////////////////////////////////////////

export const loginRequest = (email, password) => ({
	type: ADMIN_AUTHENTICATION.LOGIN_REQUEST,
	email,
	password,
})

export const loginFailure = (error) => ({
	type: ADMIN_AUTHENTICATION.LOGIN_FAILURE,
	error,
})

export const loginSuccess = (user) => ({
	type: ADMIN_AUTHENTICATION.LOGIN_SUCCESS,
	user,
})

///////////////////////////////////////////////////////////////////////

export const logout = (silenced=true) => ({
	type: ADMIN_AUTHENTICATION.LOGOUT,
	silenced,
})
