import { APPLICATIONS } from '~/actions/types'

const initialState = {
	loading: false,
	details: {},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case APPLICATIONS.CREATION_REQUEST:
		case APPLICATIONS.DETAILS_REQUEST: {
			return {
				...initialState,
				loading: true,
			}
        }

		case APPLICATIONS.CREATION_SUCCESS:
        case APPLICATIONS.DETAILS_SUCCESS:
		case APPLICATIONS.ROTATE_SECRET_SUCCESS: {
            const { application: details } = action
			return {
                ...state,
                loading: false,
                details,
            }
        }

		case APPLICATIONS.CREATION_FAILURE:
		case APPLICATIONS.DETAILS_FAILURE: {
			return initialState
		}

		default:
			return state
	}
}
