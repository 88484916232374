import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { loginRequest, logout } from '~/actions/admin/authentication'

class Login extends React.Component
{
	state = {
		email: '',
		password: '',
		submitted: false,
	}

	componentDidMount() {
		// reset login status
		const { logout } = this.props
		logout()
	}

	handleChange = (e) => {
		const { name, value } = e.target
		this.setState({ [name]: value })
	}

	handleSubmit = (e) => {
		e.preventDefault()

		const { email, password } = this.state
		this.setState({ submitted: true })

		if (email && password) {
			const { loginRequest } = this.props
			loginRequest(email, password)
		}
	}

	render() {
		const { loggingIn } = this.props
		const { email, password, submitted } = this.state
		return (
			<div>
				<h2>Login</h2>
				<form name="form" onSubmit={this.handleSubmit}>
					<div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
						<label htmlFor="email">Email</label>
						<input type="text" className="form-control" name="email" id="email" value={email} onChange={this.handleChange} />
						{ submitted && !email && (
							<div className="help-block">Email is required</div>
						)}
					</div>
					<div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
						<label htmlFor="password">Password</label>
						<input type="password" className="form-control" name="password" id="password" value={password} onChange={this.handleChange} />
						{ submitted && !password && (
							<div className="help-block">Password is required</div>
						)}
					</div>
					<div className="form-group">
						<button className="btn btn-primary">Login</button>
						{ loggingIn && (
							<img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="Loader" />
						)}
					</div>
				</form>
			</div>
		)
	}
}

const mapStateToProps = ({ admin }) => {
	const { loggingIn } = admin.authentication
	return {
		loggingIn,
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		loginRequest,
		logout,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Login)
