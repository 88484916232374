import React from 'react'
import PropTypes from 'prop-types'
import ReactTags from 'react-tag-autocomplete'

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const TagComponent = ({ tag, classNames, onDelete }) => (
	<button type="button" className={classNames.selectedTag} title={tag.id} onClick={onDelete}>
		<span className={classNames.selectedTagName}>{tag.name}</span>
	</button>
)

class InputScopeChooser extends React.PureComponent
{
	handleTagValidate = (tag) => tag.name.match(/[A-Za-z0-9_@./#&+-]/gi)

	suggestionsFilter = (suggestion, value) => {
		const escapedValue = escapeRegexCharacters(value.trim())
		if (escapedValue === '')
			return false
		const regex = new RegExp('^' + escapedValue, 'i')
		return regex.test(suggestion.id) || regex.test(suggestion.name)
	}

	render() {
		const { scope, allScopes, onAdd, onDelete } = this.props

		const suggestions = allScopes.filter(e => !scope.find(s => s.name === e.name))

		return (
			<ReactTags
				tags={scope}
				handleAddition={onAdd}
				handleDelete={onDelete}
				delimiterChars={[',', ' ', ';']}
				allowNew={false}
				placeholder='Add new scope'
				handleValidate={this.handleTagValidate}
				minQueryLength={1}
				suggestions={suggestions}
				suggestionsFilter={this.suggestionsFilter}
				tagComponent={TagComponent}
			/>
		)
	}
}

InputScopeChooser.propTypes = {
	scope: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	})).isRequired,
	onAdd: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,

	allScopes: PropTypes.array.isRequired,
}

export default InputScopeChooser
