import { takeEvery, put, call } from 'redux-saga/effects'

import { PUBLIC } from '~/actions/types'
import * as notification from '~/actions/notifications'
import handleRequestError from '~/api/handle-request-error'

import {
	userEmailVerificationRequestApi,
} from '~/api/public'
import {
	verifyUserEmailSuccess,
	verifyUserEmailFailure,
} from '~/actions/public/user-email-verification'

function* onUserEmailVerificationRequest({ id, token }) {
	try {
		yield call(userEmailVerificationRequestApi, id, token)
		yield put(verifyUserEmailSuccess())
		yield put(notification.success({
			title: 'Success',
			message: 'Email succefully verified',
		}))
	}
	catch (err) {
		const errDescr = yield call(handleRequestError, err)
		yield put(verifyUserEmailFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

export default function*() {
	yield takeEvery(PUBLIC.VERIFY_USER_EMAIL_REQUEST, onUserEmailVerificationRequest)
}
