import { CONSENT_ASK } from '~/actions/types'

const initialState = {
	loading: false,
	requested_scope: [],
	requested_scope_descriptions: {},
	client: {},
	user: {},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case CONSENT_ASK.PREPARE_ASK_REQUEST:
		case CONSENT_ASK.ASK_REQUEST: {
			return {
				...initialState,
				loading: true,
			}
		}

		case CONSENT_ASK.PREPARE_ASK_FAILURE:
		case CONSENT_ASK.PREPARE_ASK_SUCCESS:
		case CONSENT_ASK.ASK_FAILURE:
		case CONSENT_ASK.ASK_SUCCESS: {
			return initialState
		}

		case CONSENT_ASK.PREPARE_ASK_SUCCESS_WITH_DATA: {
			const { requested_scope, requested_scope_descriptions, client, user } = action
			return {
				loading: false,
				requested_scope,
				requested_scope_descriptions,
				client,
				user,
			}
		}

		default:
			return state
	}
}
