import { PUBLIC } from '~/actions/types'

const initialState = {
	loading: false,
	success: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case PUBLIC.VERIFY_USER_EMAIL_REQUEST: {
			return {
				...initialState,
				loading: true,
			}
		}

		case PUBLIC.VERIFY_USER_EMAIL_SUCCESS: {
			return {
				...initialState,
				success: true,
			}
		}

		case PUBLIC.VERIFY_USER_EMAIL_FAILURE: {
			return initialState
		}

		default:
			return state
	}
}
