import { combineReducers } from 'redux'

import notifications from './notifications'
import modals from './modals'

import adminAuthentication from './admin/authentication'
import adminApplications from './admin/applications'
import adminApplicationDetails from './admin/application-details'
import adminUsers from './admin/user-list'
import adminUserDetails from './admin/user-details'
import adminScopes from './admin/scope-list'

import consentAuthentication from './consent/authentication'
import consentAsk from './consent/ask'

import publicUserEmailVerification from './public/user-email-verification'

export default () => combineReducers({
	notifications,
	modals,
	admin: combineReducers({
		authentication: adminAuthentication,
		applications: adminApplications,
		applicationDetails: adminApplicationDetails,
		users: adminUsers,
		userDetails: adminUserDetails,
		scopes: adminScopes,
	}),
	consent: combineReducers({
		authentication: consentAuthentication,
		ask: consentAsk,
	}),
	public: combineReducers({
		userEmailVerification: publicUserEmailVerification,
	}),
})
