import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'

import Checkbox from '~/components/Checkbox'
import CenteredPanel from '~/components/CenteredPanel'
import { prepareAskRequest, askRequest } from '~/actions/consent/ask'
import { getAcronym } from '~/utils'

class Ask extends React.Component
{
	state = {
		remember: true,
		submitted: false,
	}

	componentDidMount() {
		const { location, prepareAskRequest } = this.props
		const { consent_challenge } = queryString.parse(location.search)
		this.consent_challenge = consent_challenge
		if (consent_challenge) {
			prepareAskRequest(consent_challenge)
		}
		else {
			this.setState({
				fatalError: 'An error occured.',
			})
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.loading !== state.loading) {
			const { requested_scope: grant_scopes } = props
			return {
				loading: props.loading,
				grant_scopes: Array.isArray(grant_scopes) ? grant_scopes.map(e => `grant_scopes_${e}`) : [],
			}
		}
		return null
	}

	handleChange = (e) => {
		const { name, type } = e.target
		const value = (type === 'checkbox') ? e.target.checked : e.target.value
		this.setState({ [name]: value })
	}

	_reduceCheckboxItems = (checkedItems) => {
		return [...checkedItems].reduce((acc, val) => {
			if (val[1] === true) {
				acc.push(val[0])
			}
			return acc
		}, [])
	}

	onCheckboxGroupCheck = (groupName, checkedItems) => {
		this.setState({
			[groupName]: this._reduceCheckboxItems(checkedItems),
		})
	}

	handleSubmit = (e) => e.preventDefault()

	submitConsent = (acceptConsent) => {
		if (this.consent_challenge) {
			const { remember } = this.state
			let { grant_scopes } = this.state
			this.setState({ submitted: true })

			const { askRequest } = this.props

			grant_scopes = grant_scopes.map(e => e.replace(/^(grant_scopes_)/, ''))
			askRequest(this.consent_challenge, acceptConsent, grant_scopes, remember)
		}
	}

	onAllowAccessClicked = () => this.submitConsent(true)

	onDenyAccessClicked = () => this.submitConsent(false)

	_getDefaultCheckedItems = (type) => {
		const { [type]: variable } = this.state
		return Array.isArray(variable) ? new Map(variable.map(e => ([ e, true ]))) : []
	}

	getRequestedScopeCheckboxes = (scopes) => {
		return scopes.map(e => ({
			name: `grant_scopes_${e}`,
			title: e,
		}))
	}

	getUserName = (user) => {
		if (user.name) {
			return user.name
		}
		else if (user.nickname) {
			return user.nickname
		}
		else if (user.email) {
			return user.email
		}
		return 'Unknown user'
	}

	getScopeDescription = (scope) => {
		const { requested_scope_descriptions } = this.props
		if (!(scope in requested_scope_descriptions)) {
			return null
		}
		return (
			<>
				{ ': ' }
				<span className="description">{requested_scope_descriptions[scope].description}</span>
			</>
		)
	}

	render() {
		const { loading, requested_scope, client, user } = this.props
		const { fatalError, remember, submitted } = this.state

		if (fatalError) {
			return <p>{fatalError}</p>
		}

		const userLogo = user.picture_url || ''
		const username = this.getUserName(user)
		let userAcronym = '?'

		if (!userLogo) {
			userAcronym = getAcronym(username)
		}

		const appLogo = client.logo_uri || ''
		let appName = client.client_name || ''
		let appAcronym = '?'

		if (!appLogo && appName) {
			appAcronym = getAcronym(appName)
		}
		else if (!appName) {
			appName = 'Unnamed App'
		}

		return (
			<div className="consent-ask">
				<CenteredPanel className={classNames({ 'submitted-loading': submitted && loading })}>
					<div className="consent-actors">
						{ userLogo.length !== 0 ? (
							<div className="logo logo-image size-small rounded shadowed" style={{ backgroundImage: `url(${userLogo})` }}></div>
						) : (
							<div className="logo logo-acronym size-small rounded shadowed" title={userAcronym}></div>
						)}

						{ appLogo.length !== 0 ? (
							<div className="logo logo-image size-small rounded shadowed" style={{ backgroundImage: `url(${appLogo})` }}></div>
						) : (
							<div className="logo logo-acronym size-small rounded shadowed" title={appAcronym}></div>
						)}
					</div>

					<div className="header">
						<div className="title">Authorize App</div>
						<div className="sub-title"><strong>{appName}</strong></div>
					</div>
					<div className="divider-vert"></div>
					<div className="centered-panel-content">
						<p>Hi <strong>{username}</strong>, {appName} wants access to the following information:</p>

						{/* <CheckboxGroup items={this.getRequestedScopeCheckboxes(requested_scope)} name="grant_scopes" onChecked={this.onCheckboxGroupCheck} defaultChecked={this._getDefaultCheckedItems('grant_scopes')} /> */}
						<ul className="scope-list">
						{ requested_scope.filter(scope => !['openid', 'offline', 'offline_access'].includes(scope)).map((scope, i) => (
							<li className="scope" key={i}>
								<span className="name">{scope}</span>{this.getScopeDescription(scope)}
							</li>
						))}
						</ul>

						<div className="form-group">
							{/* <p>Do you want to be asked next time when this application wants to access your data? The application will not be able to ask for more permissions without your consent.</p> */}
							<Checkbox
								id="remember"
								name="remember"
								checked={remember}
								onChange={this.handleChange}
							/>{' '}<label htmlFor="remember">Remember consent</label>
						</div>
					</div>

					<ul className="links">
				 		{ client.policy_uri && (
				 			<li>
				 				<a href={client.policy_uri}> Policy</a>
				 			</li>
				 		)}
				 		{ client.tos_uri && (
				 			<li>
				 				<a href={client.tos_uri}> Terms of Service</a>
				 			</li>
				 		)}
				 	</ul>

					<form method="POST" onSubmit={this.handleSubmit}>
						<div className="actions-bar">
							<button type="submit" className="cancel" onClick={this.onDenyAccessClicked}><i className="icon-x-circle" aria-hidden="true"></i></button>
							<button type="submit" className="success" onClick={this.onAllowAccessClicked}><i className="icon-check-circle" aria-hidden="true"></i></button>
						</div>
					</form>
				</CenteredPanel>
				{ loading && (
					<img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="Loader" className="loader" />
				)}
			</div>
		)
	}
}

const mapStateToProps = ({ consent }) => {
	const { loading, requested_scope, requested_scope_descriptions, client, user } = consent.ask
	return {
		loading,
		requested_scope,
		requested_scope_descriptions,
		client,
		user,
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		prepareAskRequest,
		askRequest,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Ask)
