import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const getInputClassname = (className) => {
	if (className === null) {
		return undefined
	}
	return classNames('form-control', className)
}

const PromptModal = ({ t, cancelAction, title, message, fields, onInputChange, confirmClickAction, setAutofocusDOMRef }) => {
	return (
		<div className="modal-content">
			<div className="modal-header">
				<h5 className="modal-title">{title}</h5>
				<button type="button" className="close" aria-label="Close" onClick={cancelAction}>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="modal-body">
				{ message && <p>{message}</p> }
				<form>
					{ fields.map(field => {
						return (
							<div className="form-group" key={field.name}>
								<label htmlFor={field.name}>{field.label}</label>
								{ field.type === 'textarea' ? (
									<textarea
										id={field.name}
										name={field.name}
										className={getInputClassname(field.className)}
										placeholder={field.placeholder}
										onChange={onInputChange}
										defaultValue={field.defaultValue}
									></textarea>
								) : (
									<input
										id={field.name}
										name={field.name}
										type={field.type || 'text'}
										className={getInputClassname(field.className)}
										placeholder={field.placeholder}
										onChange={onInputChange}
										defaultValue={field.defaultValue}
									/>
								)}
							</div>
						)
					})}
				</form>
			</div>
			<div className="modal-footer">
				<button type="button" className="success" onClick={confirmClickAction} ref={setAutofocusDOMRef}>{t('modal.actions.continue')}</button>
				<button type="button" className="default" onClick={cancelAction}>{t('modal.actions.cancel')}</button>
			</div>
		</div>
	)
}

PromptModal.propTypes = {
	cancelAction: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string,
	fields: PropTypes.array.isRequired,
	setAutofocusDOMRef: PropTypes.func.isRequired,
}

PromptModal.defaultProps = {
	fields: [],
}

export default PromptModal
