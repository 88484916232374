import { put } from 'redux-saga/effects'

import { logout } from '~/actions/admin/authentication'

export default function* handleRequestError(response) {
	if (response.statusCode === 401) {
		// auto logout if 401 response returned from api
		yield put(logout(false))
	}

	if (response.error) {
		if (response.error.constructor === Object && response.error.message) {
			const { message, reason } = response.error
			if (message && reason) {
				return [ message, reason ]
			}
			return message
		}
		else {
			return response.error
		}
	}
	else if (response.message) {
		return response.message
	}
	return 'Unknown error'
}
