import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '~/components/Checkbox'

class CheckboxGroup extends React.Component
{
	state = {
		checkedItems: (this.props.defaultChecked instanceof Map) ? this.props.defaultChecked : new Map(),
	}

	static getDerivedStateFromProps(props, state) {
		if (props.defaultChecked !== state.checkedItems) {
			return {
				checkedItems: props.defaultChecked,
			}
		}
		return null
	}

	handleChange = (e) => {
		const { name: item, checked: isChecked } = e.target
		const { checkedItems } = this.state
		const { onChecked, name: groupName } = this.props
		this.setState({
			checkedItems: checkedItems.set(item, isChecked),
		}, () => {
			if (onChecked) {
				onChecked(groupName, checkedItems)
			}
		})
	}

	render() {
		const { items } = this.props
		const { checkedItems } = this.state
		return (
			<>
				{ items.map(e => (
					<p key={e.name}>
						<Checkbox
							id={e.name}
							name={e.name}
							checked={checkedItems.get(e.name)}
							onChange={!e.modificationDisabled ? this.handleChange : undefined}
							disabled={e.modificationDisabled ? true : false}
						/>{' '}<label htmlFor={e.name}>{e.title}</label>
					</p>
				))}
			</>
		)
	}
}

CheckboxGroup.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		modificationDisabled: PropTypes.bool,
	})).isRequired,
	name: PropTypes.string.isRequired,
	onChecked: PropTypes.func,
	defaultChecked: PropTypes.any,
}

export default CheckboxGroup
