import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
	listRequest,
	creationRequest,
	updateRequest,
	deleteRequest,
} from '~/actions/admin/scopes'

class ScopeList extends React.Component
{
	componentDidMount() {
		const { listRequest } = this.props
		listRequest()
	}

	updateScope = (e, scope) => {
		e.preventDefault()
		e.stopPropagation()

		const { updateRequest } = this.props
		updateRequest(scope)
	}

	deleteScope = (e, scope) => {
		e.preventDefault()
		e.stopPropagation()

		const { deleteRequest } = this.props
		deleteRequest(scope)
	}

	render() {
		const {
			scopes,
			loading,
			creationRequest,
		} = this.props

		return (
			<>
				<h1 className="content-title">
					Scope List <button className="button default btn-create-new-above-list" onClick={creationRequest}>+ New scope</button>
				</h1>

				{ loading && <p>Loading...</p> }

				{ scopes.length ? (
					<table className="scope-list">
						<thead>
							<tr>
								<th>Id</th>
								<th>Name</th>
								<th>Description</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{ scopes.map(scope => (
								<tr key={scope.id} onClick={(e) => this.updateScope(e, scope)}>
									<td>{scope.id}</td>
									<td>{scope.name}</td>
									<td>{scope.description}</td>
									<td><i className="icon-bin" aria-hidden="true" onClick={(e) => this.deleteScope(e, scope)}></i></td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<p>No scope found.</p>
				)}
			</>
		)
	}
}

ScopeList.propTypes = {
	scopes: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
}

ScopeList.defaultProps = {
	scopes: [],
	loading: false,
}

const mapStateToProps = ({ admin }) => {
	const { scopes } = admin
	return {
		loading: scopes.loading,
		scopes: scopes.list,
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		listRequest,
		creationRequest,
		updateRequest,
		deleteRequest,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(ScopeList)
