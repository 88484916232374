import { APPLICATIONS } from '~/actions/types'

export const listRequest = () => ({
	type: APPLICATIONS.LIST_REQUEST,
})

export const listFailure = (error) => ({
	type: APPLICATIONS.LIST_FAILURE,
	error,
})

export const listSuccess = (list) => ({
	type: APPLICATIONS.LIST_SUCCESS,
	list,
})

export const creationRequest = (name, flowType) => ({
	type: APPLICATIONS.CREATION_REQUEST,
	name,
	flowType,
})

export const creationFailure = (error) => ({
	type: APPLICATIONS.CREATION_FAILURE,
	error,
})

export const creationSuccess = (application) => ({
	type: APPLICATIONS.CREATION_SUCCESS,
	application,
})

export const detailsRequest = (clientId) => ({
	type: APPLICATIONS.DETAILS_REQUEST,
	clientId,
})

export const detailsFailure = (error) => ({
	type: APPLICATIONS.DETAILS_FAILURE,
	error,
})

export const detailsSuccess = (application) => ({
	type: APPLICATIONS.DETAILS_SUCCESS,
	application,
})

export const deleteRequest = (clientId, clientName) => ({
	type: APPLICATIONS.DELETE_REQUEST,
	clientId,
	clientName,
})

export const deleteFailure = (error) => ({
	type: APPLICATIONS.DELETE_FAILURE,
	error,
})

export const deleteSuccess = () => ({
	type: APPLICATIONS.DELETE_SUCCESS,
})

export const rotateSecretRequest = (clientId, clientName) => ({
	type: APPLICATIONS.ROTATE_SECRET_REQUEST,
	clientId,
	clientName,
})

export const rotateSecretFailure = (error) => ({
	type: APPLICATIONS.ROTATE_SECRET_FAILURE,
	error,
})

export const rotateSecretSuccess = (application) => ({
	type: APPLICATIONS.ROTATE_SECRET_SUCCESS,
	application,
})

export const updateDetailsRequest = (clientId, data) => ({
	type: APPLICATIONS.UPDATE_DETAILS_REQUEST,
	clientId,
	data,
})

export const updateDetailsFailure = (error) => ({
	type: APPLICATIONS.UPDATE_DETAILS_FAILURE,
	error,
})

export const updateDetailsSuccess = () => ({
	type: APPLICATIONS.UPDATE_DETAILS_SUCCESS,
})
