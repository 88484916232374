import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class PasswordShowHide extends React.PureComponent
{
	state = {
		showPassword: true,
	}

	toggleShow = (e) => {
		e.preventDefault()
		this.setState({ showPassword: !this.state.showPassword })
	}

	render() {
		const { children } = this.props
		const { showPassword } = this.state
		return (
			<>
				{ React.cloneElement(React.Children.only(children), {
					type: showPassword ? 'password' : 'text',
				})}
				<button onClick={this.toggleShow} className={classNames('default', 'no-float')} style={{float: 'initial'}}>
					<i className={'icon-view-' + (showPassword ? 'hide' : 'show')} aria-hidden="true"></i>
				</button>
			</>
		)
	}
}

PasswordShowHide.propTypes = {
	children: PropTypes.any.isRequired,
}

export default PasswordShowHide
