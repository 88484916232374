import { put, take, race } from 'redux-saga/effects'

import { MODALS } from '~/actions/types'
import { confirmation, prompt, close as closeModal } from '~/actions/modals'

export const askConfirmationSaga = function*(confirmationMessage) {
	yield put(confirmation('Are you really sure ?', confirmationMessage))

	const { yes } = yield race({
		yes: take(MODALS.ACTION_CONFIRM),
		no: take(MODALS.ACTION_CANCEL)
	})

	yield put(closeModal())

	return !!yes
}

export const askPromptSaga = function*({ title, message, fields }) {
	yield put(prompt(title, message, fields))

	const { yes } = yield race({
		yes: take(MODALS.ACTION_PROMPT_VALUE),
		no: take(MODALS.ACTION_CANCEL)
	})

	yield put(closeModal())

	if (!!yes) {
		return yes.values
	}
	return undefined
}
