export default [
	{ title: 'ORY Hydra admin ui', slug: 'title', link: '/admin', isTitle: true },
	{ divider: true },
	{ title: 'Dashboard', slug: 'dashboard', link: '/admin/dashboard', icon: 'dashboard' },
	{ title: 'Applications', slug: 'applications', link: '/admin/applications', icon: 'settings_applications' },
	{ title: 'Scopes', slug: 'scopes', link: '/admin/scopes', icon: 'check-circle' },
	{ title: 'Users', slug: 'users', link: '/admin/users', icon: 'users' },
	{ title: 'Roles', slug: 'roles', link: '/admin/roles', icon: 'user-tie' },
	{ divider: true },
	{ title: 'Logout', slug: 'logout', link: '/admin/logout', icon: 'exit' },
]
