import { ADMIN_AUTHENTICATION } from '~/actions/types'

import getUser from '~/utils/get-admin-user'

const initialState = function() {
	const user = getUser()
	return user ? { loggedIn: true, user } : {}
}

export default (state = initialState(), action) => {
	switch (action.type) {
		case ADMIN_AUTHENTICATION.LOGIN_REQUEST: {
			const { user } = action
			return {
				loggingIn: true,
				user,
			}
		}

		case ADMIN_AUTHENTICATION.LOGIN_SUCCESS: {
			const { user } = action
			return {
				loggedIn: true,
				user,
			}
		}

		case ADMIN_AUTHENTICATION.LOGIN_FAILURE:
		case ADMIN_AUTHENTICATION.LOGOUT: {
			return {}
		}

		default:
			return state
	}
}
