import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

import { logout } from '~/actions/admin/authentication'

class Logout extends React.Component
{
	componentDidMount() {
		const { logout } = this.props
		logout()
	}

	render() {
		return <Redirect from="/admin" to="/admin/login" />
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		logout,
	}, dispatch)
)

export default connect(null, mapDispatchToProps)(Logout)
