import React from 'react'
import { css } from 'emotion'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TimeAgo from 'react-timeago'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import 'codemirror/mode/javascript/javascript'
import { Controlled as CodeMirror } from 'react-codemirror2'

import {
	tokenEndpointAuthMethod,
	getGrantTypesCheckboxes,
	responseTypesCheckboxes,
} from '~/data/creation-data'
import {
	detailsRequest,
	deleteRequest,
	rotateSecretRequest,
	updateDetailsRequest,
} from '~/actions/admin/applications'
import {
	listRequest,
} from '~/actions/admin/scopes'
import flowTypes from '~/data/oauth2-flow-types'
import Switch from '~/components/Switch'
import DivShowHide from '~/components/DivShowHide'
import CheckboxGroup from '~/components/CheckboxGroup'
import PasswordShowHide from '~/components/PasswordShowHide'
import ClickToCopyButton from '~/components/ClickToCopyButton'
import InputScopeChooser from '~/components/InputScopeChooser'

const warningIconStyle = css`
	color: red;
`

const textUpperTransformStyle = css`
	text-transform: uppercase;
`

const formStyle = css`
	> div > p {
		margin-top: 0.4em;
	}
`

const DivShowHideTitle = (
	<>
		<i className={classNames('icon-warning', warningIconStyle)} aria-hidden="true"></i>
		{' '}
		<span className={warningIconStyle}>Danger Zone</span>
	</>
)

const CodeMirrorJsonEditor = ({ name, value, onChange, indent=4 }) => {
	try {
		value = JSON.parse(value)
		value = JSON.stringify(value, null, indent)
	} catch {}
	return (
		<CodeMirror
			value={value}
			options={{
				mode: {
					name: 'javascript',
					json: true,
				},
				indentUnit: indent,
				lineNumbers: true,
				theme: 'material',
			}}
			onBeforeChange={(editor, data, value) => onChange(name, value)}
		/>
	)
}

class ApplicationDetails extends React.Component
{
	state = {}

	componentDidMount() {
		const { clientId } = this.props.match.params
		const { detailsRequest, listRequest } = this.props
		detailsRequest(clientId)
		listRequest()
	}

	static getDerivedStateFromProps(props, state) {
		if ((props.loadingDetails !== state.loadingDetails) || (props.loadingScopes !== state.loadingScopes) || (props.details.client_id !== state.details.client_id)) {
			return {
				loadingDetails: props.loadingDetails,
				loadingScopes: props.loadingScopes,
				details: props.details
			}
		}
		return null
	}

	handleChange = (e) => {
		const { name, value } = e.target
		this.setState({
			details: {
				...this.state.details,
				[name]: value,
			},
		})
	}

	handleMetadataChange = (e) => {
		const { name, value } = e.target
		const newDetails = { ...this.state.details }
		newDetails.metadata[name] = value
		this.setState({ details: newDetails })
	}

	handleChangeCodemirror = (name, value) => {
		this.setState({
			details: {
				...this.state.details,
				[name]: value,
			},
		})
	}

	onDeleteApplicationClick = () => {
		const { details, deleteRequest } = this.props
		const { client_id, client_name } = details
		deleteRequest(client_id, client_name)
	}

	onRotateSecretClick = () => {
		const { details, rotateSecretRequest } = this.props
		const { client_id, client_name } = details
		rotateSecretRequest(client_id, client_name)
	}

	onUpdateDetailsClick = (e) => {
		e.preventDefault()
		const { details, updateDetailsRequest } = this.props
		const { client_id } = details
		const {
			client_name,
			logo_uri,
			token_endpoint_auth_method,
			redirect_uris,
			allowed_cors_origins,
			post_logout_redirect_uris,
			jwks,
			jwks_uri,
			scope,
			grant_types,
			response_types,
			metadata,
		} = this.state.details

		updateDetailsRequest(client_id, {
			client_name,
			logo_uri,
			token_endpoint_auth_method,
			redirect_uris: redirect_uris.split(',').filter(e => e.length),
			allowed_cors_origins: allowed_cors_origins.split(',').filter(e => e.length),
			post_logout_redirect_uris: post_logout_redirect_uris.split(',').filter(e => e.length),
			jwks,
			jwks_uri,
			scope: this._mergeScopes(scope),
			grant_types: grant_types.map(e => e.replace(/^(grant_types_)/, '')),
			response_types: response_types.map(e => e.replace(/^(response_types_)/, '')),
			metadata,
		})
	}

	handleTagDelete = (index, t) => {
		const details = { ...this.state.details }

		const tags = details.scope.slice(0)
		tags.splice(index, 1)
		details.scope = tags

		this.setState({ details })
	}

	handleTagAddition = (tag) => {
		const details = { ...this.state.details }

		if (details.scope.find(obj => obj.name === tag.name))
			return

		const tags = [].concat(details.scope, tag)
		details.scope = tags

		this.setState({ details })
	}

	_getDefaultCheckedItems = (type) => {
		const { [type]: variable } = this.state.details
		return Array.isArray(variable) ? new Map(variable.map(e => ([ e, true ]))) : []
	}

	_mergeScopes = (scopes) => {
		const merged = scopes.reduce((acc, val) => {
			acc.push(val.id)
			return acc
		}, []).join(' ')
		return merged
	}

	_reduceCheckboxItems = (checkedItems) => {
		return [...checkedItems].reduce((acc, val) => {
			if (val[1] === true) {
				acc.push(val[0])
			}
			return acc
		}, [])
	}

	onCheckboxGroupCheck = (groupName, checkedItems) => {
		this.setState({
			details: {
				...this.state.details,
				[groupName]: this._reduceCheckboxItems(checkedItems),
			},
		})
	}

	onSwitchChanged = () => {
		const newDetails = { ...this.state.details }
		newDetails.metadata.is_first_party_client = !newDetails.metadata.is_first_party_client
		this.setState({ details: newDetails })
	}

	render() {
		const { allScopes } = this.props
		const { client_name: client_name_initial } = this.props.details
		const { loadingDetails, loadingScopes } = this.state
		const {
			client_id,
			client_name = '',
			logo_uri = '',
			token_endpoint_auth_method = 'none',
			redirect_uris = '',
			allowed_cors_origins = '',
			post_logout_redirect_uris = '',
			jwks = `{"keys":[]}`,
			jwks_uri = '',
			scope,
			metadata = {},
			fha = {},
			created_at,
		} = this.state.details
		const {
			description = '',
			flow_type: flowType,
		} = metadata
		const {
			client_secret = '',
		} = fha

		const authMethodExpanded = true || ['machine-to-machine', 'regular-web'].includes(flowType)
		const authMethods = [
			authMethodExpanded && 'client_secret_basic',
			authMethodExpanded && 'client_secret_post',
			authMethodExpanded && 'private_key_jwt',
			'none',
		].filter(Boolean)
		const isAuthMethodsDisabled = (authMethods.length !== Object.keys(tokenEndpointAuthMethod).length)

		return (
			<>
				<p><Link to="/admin/applications"><i className="icon-arrow-left2" aria-hidden="true"></i> Back to Applications</Link></p>

				{ (loadingDetails || !client_id) ? <p>loadingDetails...</p> : (
					<>
						<h1 className="content-title">Application details: {client_name_initial}</h1>

						<div>
							<p>Creation date: <TimeAgo date={created_at} /></p>

							<form method="POST" className={formStyle}>
								<div>
									<label>Name</label>
									<input type="text" name="client_name" placeholder="Application name" value={client_name} onChange={this.handleChange} />
								</div>
								<div>
									<label>Client ID</label>
									<input type="text" value={client_id} name="clientId" readOnly disabled />
									<ClickToCopyButton text={client_id} />
								</div>
								{ client_secret && (
									<div>
										<label>Client Secret</label>
										<PasswordShowHide>
											<input type="password" value={client_secret} name="client_secret" readOnly disabled />
										</PasswordShowHide>
										<ClickToCopyButton text={client_secret} />
										<p>You should really <strong>copy</strong> this secret. It will not be shown after that.</p>
									</div>
								)}
								<div>
									<label>Description</label>
									<textarea name="description" cols="30" rows="4" maxLength="140" placeholder="Add a description in less than 140 characters" value={description} onChange={this.handleMetadataChange}></textarea>
								</div>
								<div>
									<label>Application Logo</label>
									<input type="text" name="logo_uri" placeholder="https://path.to/my_logo.png" value={logo_uri} onChange={this.handleChange} />
									<p>The URL of the logo to display for the application, if none is set the default badge for this type of application will be shown. Recommended size is 150x150 pixels.</p>
								</div>
								<div>
									<label>Application Type</label>
									<select name="type" disabled value={flowType}>
										{ Object.keys(flowTypes).map(type => (
											<option key={type} value={type}>{flowTypes[type]}</option>
										))}
									</select>
								</div>
								<div>
									<label>Token Endpoint Authentication Method</label>
									<select name="token_endpoint_auth_method" disabled={isAuthMethodsDisabled} value={token_endpoint_auth_method} onChange={this.handleChange}>
										{ authMethods.map(method => (
											<option key={method} value={method}>{`${method} - ${tokenEndpointAuthMethod[method]}`}</option>
										))}
									</select>
									<p>Defines the requested authentication method for the token endpoint. Possible values are 'none' (public application without a client secret), 'client_secret_post' (application uses HTTP POST parameters) or 'client_secret_basic' (application uses HTTP Basic).</p>
								</div>
								<div>
									<label>Allowed Callback URLs</label>
									<textarea name="redirect_uris" cols="30" rows="4" value={redirect_uris || ''} onChange={this.handleChange}></textarea>
									<p>After the user authenticates we will only call back to any of these URLs. You can specify multiple valid URLs by comma-separating them (typically to handle different environments like QA or testing). Make sure to specify the protocol, <code>http://</code> or <code>https://</code>, otherwise the callback may fail in some cases.</p>
								</div>
								{/* <div>
									<label>Application Login URI</label>
									<input type="text" name="application_login_uri" placeholder="https://myapp.org/login" />
									<p>In some scenarios, Hydra will need to redirects to your application's login page. This URI needs to point to a route in your application that should redirect to your /authorize endpoint.</p>
								</div> */}
								<div>
									<label>Allowed Web (CORS) Origins</label>
									<textarea name="allowed_cors_origins" cols="30" rows="4" value={allowed_cors_origins || ''} onChange={this.handleChange}></textarea>
									<p>Comma-separated list of allowed origins for use with Cross-Origin Authentication, Device Flow and web message response mode, in the form of <code>&lt;scheme&gt; "://" &lt;host&gt; [ ":" &lt;port&gt; ]</code>, such as <code>http://login.mydomain.com</code> or <code>http://localhost:3000</code></p>
								</div>
								<div>
									<label>Allowed Logout URLs</label>
									<textarea name="post_logout_redirect_uris" cols="30" rows="4" value={post_logout_redirect_uris || ''} onChange={this.handleChange}></textarea>
								</div>
								<div>
									<label>JSON Web Key Set</label>
									<CodeMirrorJsonEditor name="jwks" value={jwks} onChange={this.handleChangeCodemirror} />
								</div>
								<div>
									<label>Remote JSON Web Key Set URL</label>
									<input type="text" name="jwks_uri" value={jwks_uri} onChange={this.handleChange} />
								</div>
								<hr/>
								<div>
									<label>OAuth 2.0 grant types</label>
									<CheckboxGroup items={getGrantTypesCheckboxes(flowType)} name="grant_types" onChecked={this.onCheckboxGroupCheck} defaultChecked={this._getDefaultCheckedItems('grant_types')} />
								</div>
								<hr/>
								<div>
									<label>Response types</label>
									<CheckboxGroup items={responseTypesCheckboxes} name="response_types" onChecked={this.onCheckboxGroupCheck} defaultChecked={this._getDefaultCheckedItems('response_types')} />
								</div>
								<hr/>
								<div>
									<label>Allowed scopes</label>
									{
										!loadingScopes && (
											<InputScopeChooser
												scope={scope}
												onAdd={this.handleTagAddition}
												onDelete={this.handleTagDelete}
												allScopes={allScopes}
											/>
										)
									}
								</div>
								<div>
									<label>Allow Skipping User Consent</label>
									<Switch
										rounded={true}
										onChanged={this.onSwitchChanged}
										enabled={metadata.is_first_party_client}
									/>
									<p>If this setting is enabled, this API will skip user consent for applications flagged as First Party.</p>
								</div>
								{/* <div>
									<label>JWT Expiration</label>
									<input type="number" name="" />
									<p>Control the expiration of the id tokens (in seconds)</p>
								</div> */}
								<div>
									<input type="submit" className="button success" value="Update settings" onClick={this.onUpdateDetailsClick} />
								</div>
							</form>
						</div>

						<br/><hr/>
						<DivShowHide title={DivShowHideTitle}>
							<div className="paragraph">
								<blockquote>
									<h4>Delete this application</h4>
									<p>All your apps using this client will stop working.</p>
									<button onClick={this.onDeleteApplicationClick} className={classNames('danger', 'no-float', textUpperTransformStyle)}>Delete</button>
								</blockquote>
							</div>
							<div className="paragraph">
								<blockquote>
									<h4>Rotate secret</h4>
									<p>All authorized apps will need to be updated with the new client secret.</p>
									<button onClick={this.onRotateSecretClick} className={classNames('danger', 'no-float', textUpperTransformStyle)}>Rotate</button>
								</blockquote>
							</div>
						</DivShowHide>
					</>
				)}
			</>
		)
	}
}

ApplicationDetails.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			clientId: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	detailsRequest: PropTypes.func.isRequired,
	deleteRequest: PropTypes.func.isRequired,
	rotateSecretRequest: PropTypes.func.isRequired,
	updateDetailsRequest: PropTypes.func.isRequired,
}

const mapStateToProps = ({ admin }) => {
	let { details = {} } = admin.applicationDetails
	const { loading: loadingDetails } = admin.applicationDetails
	const { loading: loadingScopes, list: allScopes } = admin.scopes

	// When all data is here
	if (typeof details.scope === 'string' && allScopes.length > 0) {
		const { grant_types, response_types, redirect_uris, allowed_cors_origins, post_logout_redirect_uris } = details
		details = {
			...details,
			scope: details.scope.split(' ').map(id => {
				const scope = allScopes.find(s => s.id === id)
				if (scope) {
					return scope
				}
				return {
					id,
					name: (id.charAt(0).toUpperCase() + id.slice(1)).replace('_', ' '),
					description: '',
				}
			}),
			grant_types: Array.isArray(grant_types) ? grant_types.map(e => `grant_types_${e}`) : [],
			response_types: Array.isArray(response_types) ? response_types.map(e => `response_types_${e}`) : [],
			redirect_uris: Array.isArray(redirect_uris) ? redirect_uris.join(',') : (typeof redirect_uris === 'string') ? redirect_uris : '',
			allowed_cors_origins: Array.isArray(allowed_cors_origins) ? allowed_cors_origins.join(',') : (typeof allowed_cors_origins === 'string') ? allowed_cors_origins : '',
			post_logout_redirect_uris: Array.isArray(post_logout_redirect_uris) ? post_logout_redirect_uris.join(',') : (typeof post_logout_redirect_uris === 'string') ? post_logout_redirect_uris : '',
		}
	}

	return {
		loadingDetails,
		details,
		loadingScopes,
		allScopes,
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		detailsRequest,
		deleteRequest,
		rotateSecretRequest,
		updateDetailsRequest,
		listRequest,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetails)
