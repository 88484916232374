import { CONSENT_AUTHENTICATION } from '~/actions/types'

const initialState = {
	loading: false,
	client: {},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case CONSENT_AUTHENTICATION.PREPARE_LOGIN_REQUEST:
		case CONSENT_AUTHENTICATION.LOGIN_REQUEST: {
			return {
				...initialState,
				loading: true,
			}
		}

		case CONSENT_AUTHENTICATION.PREPARE_LOGIN_FAILURE: {
			return initialState
		}

		case CONSENT_AUTHENTICATION.PREPARE_LOGIN_SUCCESS: {
			const { client } = action
			return {
				...initialState,
				loading: false,
				client,
			}
		}

		case CONSENT_AUTHENTICATION.LOGIN_FAILURE:
		case CONSENT_AUTHENTICATION.LOGIN_SUCCESS: {
			return initialState
		}

		default:
			return state
	}
}
