import React from 'react'
import { css } from 'emotion'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TimeAgo from 'react-timeago'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {
	detailsRequest,
	deleteRequest,
	updateDetailsRequest,
	blockUserRequest,
	changeUserPasswordRequest,
} from '~/actions/admin/users'
import Checkbox from '~/components/Checkbox'
import DivShowHide from '~/components/DivShowHide'
import ClickToCopyButton from '~/components/ClickToCopyButton'

const warningIconStyle = css`
	color: red;
`

const textUpperTransformStyle = css`
	text-transform: uppercase;
`

const leftPaneStyle = css`
	float: left;
`
const rightPaneStyle = css`
	float: right;
	height: 90px;
`
const rightPaneImageStyle = css`
	width: auto;
	height: 100%;
`
const panesClearStyle = css`
	clear: both;
`

const DivShowHideTitle = (
	<>
		<i className={classNames('icon-warning', warningIconStyle)} aria-hidden="true"></i>
		{' '}
		<span className={warningIconStyle}>Danger Zone</span>
	</>
)

class UserDetails extends React.Component
{
	state = {}

	componentDidMount() {
		const { userId } = this.props.match.params
		const { detailsRequest } = this.props
		detailsRequest(userId)
	}

	static getDerivedStateFromProps(props, state) {
		if ((props.loading !== state.loading) || (props.details.id !== state.details.id)) {
			return {
				loading: props.loading,
				details: {
					...props.details,
				},
			}
		}
		return null
	}

	handleChange = (e) => {
		const { name, value } = e.target
		this.setState({
			details: {
				...this.state.details,
				[name]: value,
			},
		})
	}

	onDeleteUserClick = () => {
		const { details, deleteRequest } = this.props
		const { id, email } = details
		deleteRequest(id, email)
	}

	onUpdateDetailsClick = (e) => {
		e.preventDefault()
		const { details, updateDetailsRequest } = this.props
		const { id } = details
		const {
			name,
			nickname,
			picture_url,
		} = this.state.details
		updateDetailsRequest(id, {
			name,
			nickname,
			picture_url,
		})
	}
	
	onBlockUserClick = (e) => {
		e.preventDefault()
		const { details, blockUserRequest } = this.props
		const { id, email } = details
		const { blockedAt } = this.state.details
		const isBlocked = (typeof blockedAt === 'string' && blockedAt.trim().length > 0)
		blockUserRequest(id, email, !isBlocked)
	}

	onChangeUserPasswordClick = (e) => {
		e.preventDefault()
		const { details, changeUserPasswordRequest } = this.props
		const { id } = details
		changeUserPasswordRequest(id)
	}

	render() {
		const { loading } = this.props
		const {
			id: userId,
			email = '',
			name,
			nickname,
			picture_url,
			loginCount,
			verifiedAt,
			blockedAt,
			createdAt,
		} = this.state.details
		const {
			picture_url: basePictureUrl,
		} = this.props.details
		const isVerified = (typeof verifiedAt === 'string' && verifiedAt.trim().length > 0)
		const isBlocked = (typeof blockedAt === 'string' && blockedAt.trim().length > 0)
		return (
			<>
				<p><Link to="/admin/users"><i className="icon-arrow-left2" aria-hidden="true"></i> Back to Users</Link></p>

				{ (loading || !userId) ? <p>Loading...</p> : (
					<>
						<h1 className="content-title">User details: {email}</h1>

						<div>
							<p>Creation date: <TimeAgo date={createdAt} /></p>
							<p>Login count: {loginCount}</p>
							<div>
								<div className={leftPaneStyle}>
									<div>
										<p>
											<Checkbox
												id="isVerified"
												name="isVerified"
												checked={isVerified}
												disabled={true}
											/>{' '}<label htmlFor="isVerified">Is verified</label>
										</p>
									</div>
									<div>
										<p>
											<Checkbox
												id="isBlocked"
												name="isBlocked"
												checked={isBlocked}
												disabled={true}
											/>{' '}<label htmlFor="isBlocked">Is blocked</label>
										</p>
									</div>
								</div>
								<div className={rightPaneStyle}>
									<img src={basePictureUrl || '/static/user-default.svg'} alt="User avatar" className={rightPaneImageStyle} />
								</div>
								<div className={panesClearStyle}></div>
							</div>
							<form method="POST">
								<div>
									<label>Email</label>
									<input type="email" name="email" value={email} readOnly disabled />
									<ClickToCopyButton text={email} />
								</div>
								<div>
									<label>User ID</label>
									<input type="text" name="userId" value={userId} readOnly disabled />
								</div>
								<div>
									<label>Name</label>
									<input type="text" name="name" value={name || ''} onChange={this.handleChange} />
								</div>
								<div>
									<label>Nickname</label>
									<input type="text" name="nickname" value={nickname || ''} onChange={this.handleChange} />
								</div>
								<div>
									<label>Picture url</label>
									<input type="text" name="picture_url" value={picture_url || ''} onChange={this.handleChange} />
								</div>
								<div>
									<input type="submit" className="button success" value="Update settings" onClick={this.onUpdateDetailsClick} />
								</div>
							</form>
						</div>

						<br/><hr/>
						<DivShowHide title={DivShowHideTitle}>
							<div className="paragraph">
								<blockquote>
									<h4>Delete this user</h4>
									<p>The user will be removed and it will no longer have access to your applications.</p>
									<button onClick={this.onDeleteUserClick} className={classNames('danger', 'no-float', textUpperTransformStyle)}>Delete</button>
								</blockquote>
							</div>
							<div className="paragraph">
								<blockquote>
									<h4>{ isBlocked ? 'Unblock' : 'Block' } user</h4>
									<p>The user will be { isBlocked ? 'unblocked' : 'blocked' } for logging into your applications.</p>
									<button onClick={this.onBlockUserClick} className={classNames('danger', 'no-float', textUpperTransformStyle)}>{ isBlocked ? 'Unblock' : 'Block' }</button>
								</blockquote>
							</div>
							<div className="paragraph">
								<blockquote>
									<h4>Change Password</h4>
									<p>Once you change it, the user will not be able to log in using their previous password.</p>
									<button onClick={this.onChangeUserPasswordClick} className={classNames('danger', 'no-float', textUpperTransformStyle)}>Change</button>
								</blockquote>
							</div>
						</DivShowHide>
					</>
				)}
			</>
		)
	}
}

UserDetails.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			userId: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	detailsRequest: PropTypes.func.isRequired,
	deleteRequest: PropTypes.func.isRequired,
	updateDetailsRequest: PropTypes.func.isRequired,
	blockUserRequest: PropTypes.func.isRequired,
	changeUserPasswordRequest: PropTypes.func.isRequired,
}

const mapStateToProps = ({ admin }) => {
    const { loading, details } = admin.userDetails
	return {
		loading,
		details,
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		detailsRequest,
		deleteRequest,
		updateDetailsRequest,
		blockUserRequest,
		changeUserPasswordRequest,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
